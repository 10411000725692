@import "./styles/variables";
@import "./styles/home-page";
@import "./styles/pricing";
@import "./styles/header";
@import "./styles/auth";
@import "./styles/licence-agreement";
@import "./styles/about-page";
@import "./styles/advertisement-marketing";
@import "./styles/footer";
@import "./styles/advertisement";
@import "./styles/file-upload";
@import "./styles/affiliate-landing";
@font-face {
  font-family: "BebasNeue";
  src: url("fonts/BebasNeuewebfont.ttf"),
    url("fonts/BebasNeuewebfont.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("fonts/BebasNeuewebfont.woff") format("woff"); /* Pretty Modern Browsers */
}
@font-face {
  font-family: "Aller";
  src: url("fonts/Aller_Rg.ttf"), url("fonts/Aller_Rg.ttf") format("truetype"); /* Safari, Android, iOS */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main: #00b7ff;
  --mainHover: #58d0ff;
  min-height: 100vh;
}

body {
  color: #606c76;
  font-family: "Aller", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.01em;
}

#root {
  display: flex;
  position: relative;
  margin-top: $header-height-mobile;
  min-height: calc(100vh - #{$header-height-mobile});
  padding-bottom: $footer-height-mobile;
  width: 100%;

  @media (min-width: 900px) {
    margin-top: $header-height-desktop;
    min-height: calc(100vh - #{$header-height-desktop});
    padding-bottom: $footer-height-desktop;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.page-width-fix {
  width: 100vw;
  overflow: hidden;
}

.public-page,
#forgotPass {
  width: 100%;
  height: 100%;
}

.public-page {
  display: contents;
}

#forgotPass {
  position: absolute;
}

a {
  color: var(--main);
  text-decoration: none;
  cursor: pointer;
  &.active {
    font-weight: bold;
  }
  &:hover {
    color: var(--mainHover);
  }
}
h1,
h2 {
  margin-bottom: 1rem;
  font-family: "Montserrat";
  text-transform: uppercase;
}
button,
.button {
  background: var(--main);
  border: none;
  font-family: "Montserrat";
  color: #fff;
  font-size: 0.95rem;
  font-weight: 400;
  padding: 0.6em 0.9em;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  text-align: center;
  &:hover {
    background: var(--mainHover);
    color: #fff;
  }
  &.close {
    background: none;
    color: #606c76;
    font-size: 2rem;
    padding: 0;
    &:hover {
      color: #000;
    }
  }
  &.add-new {
    position: absolute;
    left: 300px;
    top: 0;
    background: #9c27b0;
  }
  &.purple {
    background: #9c27b0;
  }
  &.red {
    background: #ef5350;
    &:hover {
      background: #f88c8a;
    }
  }
  &[disabled] {
    opacity: 0.5;
    background: #999;
    cursor: not-allowed;
  }
  &.outline {
    background: none;
    color: var(--main);
    border: var(--main) 1px solid;
  }
}
.pending {
  background: #607d8b40;
  padding: 5px;
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  color: #607d8b;
  text-align: center;
  &.Accepted {
    background: #4caf50;
    color: #fff;
    &:empty {
      display: none;
    }
  }
  &.Declined {
    background: #ef5350;
    color: #fff;
  }
}
.declined {
  opacity: 0.5;
}
button.matches {
  background: #ef5350;
}
.row {
  flex-direction: row;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  display: flex;
}
.row .column {
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
  padding: 0 1rem;
}
.bold {
  font-weight: bold;
}
fieldset {
  border-width: 0;
  padding: 0;
}
label {
  display: block;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
  font-family: "Montserrat";
}
input,
textarea,
select,
.stripe_input {
  width: 100%;
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  border: #ddd 1px solid;
  margin-bottom: 0.7rem;
  font-size: 0.95rem;
  color: #606c76;
  font-family: "Aller";
  font-weight: 500;
  &[disabled] {
    background: #f2f2f2;
  }
}
select {
  background: #fff;
  height: 36px;
}
#signUp,
#signIn {
  position: relative;
  width: 100%;
  .footer {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.error {
  color: red;
  font-size: 0.8rem;
  font-family: "Montserrat";
  margin-top: -5px;
  margin-bottom: 20px;
  display: block;
}
.page {
  width: 100%;
  transition: all 0.3s;
  background: #fff;
  display: none;
  &.show {
    display: block;
  }
}
.ajax {
  pointer-events: none;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    background-image: url("/img/Double-Ring-1.3s-200px.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10% 10%;
  }
}

#map-canvas {
  width: 50%;
  height: calc(100vh - 150px);
  float: right;
}
.steps {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  div {
    text-align: center;
    width: 19.5%;
    border-bottom: #ccc 4px solid;
    transition: all 0.3s;
    padding: 10px 0;
    border-radius: 5px 5px 0 0;
    h4 {
      margin: 0;
    }
    &.active {
      // background-image: linear-gradient(to left, #0db0c9, #017fa4);
      color: #fff;
      border-bottom: 4px solid $primary-orange;
    }
    &.done {
      border-bottom: 4px solid rgba($primary-orange, 0.5);
    }
  }
}
.trial .steps div {
  width: 33%;
}
#ad-packages {
  position: relative;
  h1 {
    text-align: center;
  }
  section {
    color: #999;
    text-align: left;
    font-size: 18px;
    z-index: 2;
    div {
      margin-bottom: 50px;
      cursor: pointer;
      svg {
        width: 32px;
        height: 32px;
        float: left;
        margin-right: 10px;
        margin-bottom: 30px;
      }
      li {
        clear: both;
        margin-left: 50px;
        font-size: 13px;
      }
    }
    small {
      display: block;
      margin-bottom: 5px;
    }
  }
  .selected {
    color: #f29338;
  }
}
#region-map {
  width: 50%;
  @media (max-width: 700px) {
    width: 100%;
  }
}
.customize-ad-png {
  float: left;
  width: 400px;
  margin-right: 50px;
  @media (max-width: 700px) {
    width: 90%;
    float: none;
    margin-right: 0;
    margin-bottom: 40px;
  }
}
.ad-packages-notes-li {
  color: #afadad;
  text-align: left;
  font-size: 15px;
  margin-bottom: 30px;
  strong {
    display: block;
    color: #fff;
  }
  li {
    margin-bottom: 5px;
  }
}
.modal {
  position: fixed;
  top: -130%;
  width: 700px;
  left: calc(50% - 350px);
  background: #fff;
  // background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  box-shadow: rgba(0, 0, 0, 0.2) 0 10px 20px;
  padding: 20px;
  transition: all 0.3s;
  z-index: 101;
  border-radius: 0 0 5px 5px;
  overflow: auto;
  max-height: 90vh;
  &.show {
    top: 0;
  }
  .close {
    width: auto;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  &.search {
    width: 90%;
    left: 5%;
    max-height: 97vh;
  }
  &.advertising-plans {
    width: 1000px;
    left: calc(50% - 500px);
    max-height: 97vh;
    background-color: #45505a;
    color: #fff;
    @media (max-width: 700px) {
      width: 95%;
      left: 2.5%;
    }
  }
  label {
    text-transform: uppercase;
  }
  .row {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: #f0f0f0 1px solid;
    i {
      font-weight: bold;
    }
  }
}
.safari .modal {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
}
#lead-types-modal {
  width: 800px;
  left: calc(50% - 400px);
  p,
  li {
    margin: 20px 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  ol {
    margin: 0 30px;
  }
  table {
    width: 750px;
    margin-left: 5px;
    td {
      text-align: center;
      border: #ccc 1px solid;
      width: 20%;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }
    td.green {
      background: green;
    }
  }
}
.dashboard-footer {
  margin-top: 20px;
  button {
    margin-right: 5px;
  }
}
.warning {
  background: #ef5350;
  color: #fff;
  padding: 10px;
  margin: 15px 0;
  border-radius: 4px;
  a {
    text-decoration: underline;
    color: #fff;
  }
}
#admin-menu {
  position: fixed;
  width: 250px;
  top: 0;
  height: 100vh;
  background: #031e29;
  z-index: 1;
  a {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    padding: 10px 16px;
    margin: 5px 0;
    transition: all 0.3s;
    font-family: "Montserrat";
    position: relative;
    &:hover {
      background: #05384d;
    }
    &.active {
      color: var(--main);
    }
    svg {
      width: 24px;
      height: 24px;
      float: left;
      position: relative;
      margin-right: 16px;
      top: -2px;
    }
  }
  .logo {
    background: #e1f0fa;
    padding: 5px;
    margin-bottom: 15px;
    text-align: center;
    img {
      height: 70px;
    }
  }
}
#admin-page {
  margin-left: 270px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  ul:not(.react-datepicker__time-list) {
    margin: 15px 30px;
  }
}
.container {
  width: 95%;
  max-width: 1050px;
  margin: auto;
  position: relative;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 20px;
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  font-size: 0.9rem;
  background: #fff;
  border-bottom: #e1f0fa 1px solid;
  // background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  a {
    color: var(--main);
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  span {
    margin-right: 20px;
  }
  nav {
    width: 100%;
    img {
      height: 20px;
      float: left;
    }
  }
}
.safari header {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
}
#new-case-button {
  float: right;
}
#map-canvas-search-vehicle {
  height: calc(100vh - 200px);
}
#map-canvas-alert {
  height: 200px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th {
  text-align: left;
  font-family: "Montserrat";
  padding: 6px 3px;
  border-bottom: #ccc 1px solid;
  font-size: 0.9rem;
  a {
    cursor: pointer;
    display: flex;
    svg {
      margin-left: 4px;
    }
  }
  img {
    height: 30px;
  }
  &.img {
    width: 60px;
  }
}
td {
  padding: 6px 3px;
  font-size: 0.9rem;
  position: relative;
  button,
  .button {
    margin-right: 3px;
    font-size: 0.85rem;
    padding: 4px 10px;
    margin: 0;
    width: 100%;
  }
  &:last-of-type {
    text-align: right;
  }
}
tr {
  border-bottom: #eee 1px solid;
}
tbody tr:hover {
  background: #f0f0f0;
}
tr.expander {
  display: none;
  &.show {
    display: table-row;
  }
  td {
    text-align: left;
    padding: 0;
  }
}
.exp {
  background: #f0f0f0;
  padding: 10px;
  strong {
    font-family: "Montserrat";
    text-transform: capitalize;
  }
  p {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: 700px) {
      margin-bottom: 5px;
      font-size: 9px;
      zoom: 1;
    }
  }
  &.two-columns {
    overflow: hidden;
    p {
      width: 50%;
      float: left;
      @media (max-width: 700px) {
        width: 100%;
        float: none;
      }
    }
  }
  .exp {
    padding: 10px 0 0;
  }
  h3 {
    font-family: Montserrat;
    font-size: 18px;
    text-transform: uppercase;
    margin: 30px 0 0px;
  }
}
.center {
  text-align: center;
}
.link {
  cursor: pointer;
}
.plan-headers {
  max-width: 990px;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
#plans {
  max-width: 990px;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  // justify-content: center;
  .box-holder {
    width: 311px;
    height: 100%;

    h2 {
      text-align: center;
      width: 100%;
      font-family: BebasNeue;
      font-size: 42px;
      color: #dddddd;
    }
  }
  .box {
    border-radius: 8px;
    box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.7);
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    .top {
      border-radius: 8px 8px 0 0;
      background-image: linear-gradient(to left, #0fc2dd, #0295bf);
      font-family: BebasNeue;
      color: #ffffff;
      text-align: center;
      font-size: 22px;
      .price {
        font-size: 45px;
        padding-top: 15px;
      }
      .line {
        width: 149px;
        height: 3px;
        background-color: #f29338;
        margin: 2px auto 10px;
      }
      span {
        font-family: Aller;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .top2 {
      background-image: linear-gradient(to left, #0db0c9, #017fa4);
    }
    .top3 {
      background-image: linear-gradient(to left, #0b9db3, #00617d);
    }
    .triangle {
      max-width: 100%;
    }
  }
  .li {
    margin: 15px 20px 15px 50px;
    display: flex;
    font-size: 17px;
    color: #666666;
    font-weight: bold;
    text-align: left;
    .super {
      font-size: 9px;
      font-weight: bold;
      position: relative;
      top: -8px;
      left: -3px;
    }
    small {
      display: block;
      font-size: 13px;
      color: #aaaaaa;
      margin-top: 5px;
    }
    svg {
      margin-right: 10px;
    }

    .price-wrapper {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 400px) {
        flex-direction: column;
      }
    }

    .price {
      font-family: BebasNeue;
      font-size: 25px;
      font-weight: normal;
      color: #f29338;
      display: inline-block;
      margin-top: 8px;
      span {
        font-size: 13px;
        font-weight: bold;
      }

      &-black {
        color: #777777;
      }
    }
  }
  .line2 {
    height: 1px;
    background-color: #eeeeee;
    margin: 10px 50px 10px 50px;
  }
  button {
    width: 178px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #f29338;
    display: block;
    margin: 15px auto;
    text-transform: none;
  }
  .LIMITED-OFFER {
    width: 130px;
    text-align: center;
    transform: rotate(45deg);
    font-family: BebasNeue;
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    background-color: #f29338;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);
    top: 25px;
    right: -33px;
  }
  &.lead-plans {
    .box-holder {
      width: auto;
    }
    .box {
      .top {
        .price {
          font-size: 36px;
        }
      }
    }
    .li,
    .line2 {
      margin: 15px 5px 15px 10px;
    }
  }
}
.trial #plans {
  justify-content: center;
  .box-holder {
    width: 400px;
  }
}
.note {
  color: var(--main);
}
.stripe_secure_logo {
  margin-top: 30px;
  font-size: 0.8rem;
}
.stripe_cards_logo img {
  height: 40px;
}
.stripe_company_info {
  font-size: 0.8rem;
}
#as-react-datatable-container {
  .row {
    display: block;
    margin: 0;
    width: 100%;
    margin-top: 40px;
  }
}
#as-react-datatable-table-foot {
  font-size: 0.8rem;
  padding-top: 1em;
  opacity: 0.5;
}
.table_tools {
  float: right;
}
.buttons-excel,
.buttons-pdf {
  padding: 8px 15px;
  background: #009688;
  margin-right: 4px !important;
  &:last-of-type {
    margin-right: 0 !important;
  }
}
.fa-file-excel-o,
.fa-print {
  background: url(/img/excel.svg);
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}
.fa-print {
  background: url(/img/printer.svg);
}
.input-group {
  position: relative;
  width: 100%;
  display: flex;
  .clear-search {
    font-size: 1.9rem;
    padding: 0;
    background: none;
    color: #aaa;
    position: absolute;
    right: 2px;
    top: 3px;
    &[disabled] {
      opacity: 0;
    }
  }
}
.with-button {
  .clear-search {
    right: 105px;
  }
}
.city-search-select,
.vehicle-select,
.county-search-select,
.select {
  margin-bottom: 10px;
  & > div {
    border: #ddd 1px solid;
  }
  & > div:nth-of-type(2) {
    z-index: 4;
  }
}
.inline {
  display: inline;
  margin-right: 20px;
  input {
    width: auto;
    margin-right: 8px;
  }
}
.yearButtons {
  background: #aaa;
  font-size: 0.7rem;
  margin-right: 5px;
}
// .bigger-inputs {
// 	input {
// 		padding: 0.8rem 0.5rem;
// 	}
// 	.clear-search {
// 		top: 8px;
// 	}
// }
.line {
  border-bottom: #eee 1px solid;
  margin: 20px 0 30px;
}
.search-footer {
  margin-top: 30px;
  padding-top: 43px;
  display: flex;
  justify-content: space-between;
  border-top: #eee 1px solid;
  button {
    width: 49%;
    padding: 1em;
    font-size: 1.5em;
  }
}
#tabs {
  width: 100%;
  border-bottom: #ccc 1px solid;
  button {
    position: relative;
    background: #f0f0f0;
    color: #606c76;
    border: #ccc 1px solid;
    border-radius: 4px 4px 0 0;
    padding: 0.8em 1em;
    top: 1px;
    outline: none;
    font-size: 0.9em;
    &.active {
      background: #fff;
      border-bottom: #fff 1px solid;
      @media (max-width: 700px) {
        border-bottom: #ccc 1px solid;
      }
    }
    @media (max-width: 700px) {
      border-radius: 4px;
      margin: 2px;
    }
  }
  @media (max-width: 700px) {
    border: none;
  }
}
.tab {
  padding: 30px;
  display: none;
  border-left: #ccc 1px solid;
  border-right: #ccc 1px solid;
  border-bottom: #ccc 1px solid;
  border-radius: 0 0 4px 4px;
  position: relative;
  // overflow: hidden;
  &.show {
    display: block;
  }
  .footer {
    text-align: right;
    margin-top: 20px;
  }
  .add-new {
    position: relative;
    top: auto;
    left: auto;
    margin-top: 30px;
  }
}
.account-logo {
  cursor: pointer;
  max-width: 200px;
  max-height: 200px;
  border: #ccc 1px solid;
  padding: 10px;
  border-radius: 4px;
}
.accident-matches {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 1.7em;
  line-height: 1.5em;
  display: block;
}
.intl-tel-input.allow-dropdown .selected-flag {
  display: none;
}
.dev .intl-tel-input.allow-dropdown .selected-flag {
  display: block;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="tel"],
.intl-tel-input.allow-dropdown input[type="text"] {
  padding-left: 10px !important;
}

.dev .intl-tel-input.allow-dropdown input,
.dev .intl-tel-input.allow-dropdown input[type="tel"],
.dev .intl-tel-input.allow-dropdown input[type="text"] {
  padding-left: 50px !important;
}

.cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  @media (max-width: 700px) {
    margin-bottom: 10px;
  }
}
.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 30px;
  height: 30px;
  transform: scale(1);
  vertical-align: middle;
  border: $primary-orange 3px solid;
  transition: all 0.2s ease;
}
.cbx span:first-child svg {
  position: absolute;
  top: 6px;
  left: 5px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: $primary-orange;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.cbx span:last-child {
  padding-left: 8px;
}
.cbx:hover span:first-child {
  border-color: $primary-orange;
}

.inp-cbx:checked + .cbx span:first-child {
  background: $primary-orange;
  border-color: $primary-orange;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
.owner {
  border-bottom: #eeeeee 1px solid;
  margin-bottom: 20px;
  .row {
    margin-bottom: 20px;
  }
}
.mobile-menu-toggle {
  flex-direction: column;
  align-items: center;
  padding: 0;
  border: none;
  outline: 0;
  background: transparent;

  span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: var(--main);
    margin-bottom: 4px;
    border-radius: 5px;
  }
  display: none;
}
.loader-gif {
  margin-right: 7px;
}
[data-title] {
  position: relative;
  cursor: help;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -30px;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  white-space: nowrap;
  z-index: 999;
  font-family: "Aller";
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}
[data-title]:hover::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 5px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.9);
}
[data-title-right] {
  position: relative;
  cursor: help;
}

[data-title-right]:hover::before {
  content: attr(data-title-right);
  position: absolute;
  left: -240px;
  bottom: -30px;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  white-space: nowrap;
  z-index: 999;
  font-family: "Aller";
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}
[data-title-right]:hover::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 25px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.9);
}
.saveStatus {
  text-align: right;
  transition: all 0.3s;
  opacity: 0;
  margin-top: 5px;
  &.show {
    opacity: 1;
  }
}
.alert-options {
  a {
    display: block;
    cursor: pointer;
    padding: 15px;
    border: #ddd 1px solid;
    margin: 10px 0;
    transition: all 0.3s;
    &.selected {
      background: var(--main);
      color: #fff;
      border: var(--main) 1px solid;
    }
  }
  .option-box {
    padding: 15px;
    border: var(--main) 1px solid;
    margin: 0;
    width: auto;
    margin-top: -10px;
    display: none;
    &.show {
      display: block;
    }
  }
}
@media only screen and (max-width: 700px) {
  .mobile-menu-toggle {
    display: inline-flex;
    margin-left: 20px;
    margin-right: 10px;
    top: -9px;
    position: relative;
  }
  .modal {
    width: 100vw;
    left: 0;
    max-height: 95vh;
    overflow: auto;
  }
  .hide-on-mobile {
    display: none;
  }
  #admin-menu {
    left: -250px;
    transition: all 0.3s;
    .logo {
      img {
        max-width: 55px;
        height: auto;
      }
    }
  }
  #admin-page {
    margin: 0px;
    padding: 10px;
    width: 100vw;
    overflow: auto;
  }
  .row {
    display: block;
    .column {
      display: block;
    }
  }
  header {
    padding: 6px 5px;
    background: #fff;
    z-index: 1;
    nav {
      margin-top: 8px;
      img {
        margin-left: 10px;
        height: 32px;
        margin-top: -7px;
      }
    }
  }
  .mobile-menu {
    #admin-menu {
      left: 0;
    }
  }
  .Footer.loggedin {
    margin-left: 0;
    @media (max-width: 700px) {
      padding: 10px;
      text-align: center;
    }
  }
  #tabs button {
    padding: 0.4em;
  }
  .tab {
    padding: 5px;
    overflow: auto;
    @media (max-width: 700px) {
      overflow-x: auto;
      overflow-y: hidden;
      border: none;
      margin-top: 10px;
    }
  }
  .search-footer {
    display: block;
  }
  .search-footer button {
    width: 100%;
    display: block;
  }
  #signUp #plans .tabs a,
  #admin-page #plans .tabs a {
    color: #666;
  }
  #plans {
    scroll-snap-type: x mandatory;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    .box-holder {
      min-width: 80vw;
      margin: 0 20px 20px;
      display: block;
      scroll-snap-align: center;
    }
  }
  #plans.lead-plans {
    display: block;
    overflow: auto;
    scroll-snap-type: none;
  }
}
.get-started-leads-button {
  float: right;
  margin: -20px 40px 100px;
  @media (max-width: 700px) {
    float: none;
    margin: 20px 20px 40px;
    text-align: center;
  }
}
.learn-more-about-our-leads {
  width: 400px;
  margin: auto;
  background: #f29338;
  display: block;
  font-size: 2rem;
  font-family: "BebasNeue";
  @media (max-width: 700px) {
    width: 90%;
    padding: 10px;
    font-size: 1.8rem;
  }
}
#zip-quota-checker {
  width: 364px;
  max-width: calc(100vw - 20px);
  .input {
    line-height: 26px;
  }
  .result {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 440px;
    margin-top: 10px;
    color: #ffffff;
    :empty {
      margin-bottom: 0;
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .form-input {
    z-index: 1;
    width: 207px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .chosen-result {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: #eeeeee;

      .arrow-down {
        position: absolute;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 12px solid #333333;
        border-radius: 3px;

        &:hover {
          border-top-color: #999999;
        }
      }
    }
  }
  .zip-result {
    &-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 640px;
      max-width: calc(100vw - 40px);
      height: fit-content;
      border-radius: 5px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      padding: 42px 41px 27px 41px;
      z-index: 1;
      .zip-result-x-button {
        background-color: transparent;
        position: absolute;
        width: 14.5px;
        height: 14.5px;
        top: 24.5px;
        right: 24.5px;
        padding: 0;
        margin: 0;
        box-shadow: none;
        &:hover {
          cursor: pointer;
        }
        &-image {
          width: 14.5px;
          height: 14.5px;
          object-fit: contain;
        }
      }
      .zip-result-result {
        text-align: center;
        font-family: BebasNeue;
        font-size: 50px;
        line-height: 1.1;
        text-align: center;
        color: #f29338;
        margin-bottom: 4px;
      }
      .zip-result-zip {
        text-align: center;
        font-family: Helvetica;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: #666666;
        margin-bottom: 0;
        &-underline {
          margin: auto;
          width: 116px;
          height: 5px;
          background-color: #666666;
          margin-bottom: 20px;
        }
      }
      .zip-result-text {
        text-align: center;
        font-family: Aller;
        font-size: 15px;
        text-align: center;
        color: #aaaaaa;
        margin-bottom: 15px;
      }
      .zip-result-gray-box {
        margin: auto;
        width: 558px;
        height: 109px;
        background-color: #ebebeb;
        margin-bottom: 19px;
        max-width: 100%;
      }

      .zip-result-sign-up {
        margin: auto;
      }
    }
  }
}

.dropdown-options {
  z-index: 2;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2px;
  border: 3px solid #d3dbe0;
  border-radius: 3px;
  max-height: 200px;
  overflow-y: scroll;
  width: 200px;
  -webkit-box-shadow: 0px 5px 14px -4px rgba(227, 243, 255, 0.91);
  -moz-box-shadow: 0px 5px 14px -4px rgba(227, 243, 255, 0.91);
  box-shadow: 0px 5px 14px -4px rgba(227, 243, 255, 0.91);
  .dropdown-option {
    padding: 4px 3px 4px 7px;
    font-family: Helvetica;
    cursor: pointer;
    &:nth-child(odd) {
      background-color: #ebf7fc;
      border-bottom: 1px solid #f1f1f1;
    }
    &:nth-child(even) {
      background-color: #e3f3ff;
    }
    text-align: left;
  }
}
.dropdown-input {
  width: 100% !important;
  border: none;
  margin: 5px 0 0 -8px;
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.dropdown-break {
  border-bottom: 3px solid #dddddd;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  height: 0;
  width: 125px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 3px;
}

.chosen-result {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  height: 50px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* background-color: #fff;
	background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
		linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%; */
}
.chosen-result::-ms-expand {
  display: none;
}
.chosen-result:hover {
  border-color: #888;
}
.chosen-result:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.chosen-result option {
  font-weight: normal;
  font-family: Helvetica;
}
.match {
  background: #f0f0f0;
  padding: 5px;
  margin-bottom: 5px;
  border: #f0f0f0 1px solid;
  position: relative;
  line-height: 24px;
  border-radius: 4px;
  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  strong {
    display: inline-block;
    width: 100px;
    text-align: right;
    margin-right: 5px;
  }
  &.selected {
    border: #00b7ff 1px solid;
    background: #ebf7fc;
  }
}
.customize-ad-popup {
  background: rgba(255, 255, 255, 0.95);
  padding: 10px;
  width: 100%;
  height: 100%;
  position: fixed;
  object-fit: contain;
  transition: all 0.3s;
  z-index: 10;
}
.customize-ad-popup-i {
  display: inline-block;
  top: 3px;
  position: relative;
}
@media (min-width: 700px) {
  #plans {
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20px;

    .box-holder {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .customize-ad-popup {
    background: none;
    width: 480px;
    height: 645px;
    margin-top: 200px;
    position: relative;
    padding: 0;
  }
  .customize-ad-popup-i {
    display: none;
  }
  #pricing .customize-ad-popup {
    margin-top: 0;
  }
}
#map-dash {
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
}
#map-search-results {
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
}
#search-controls {
  width: 230px;
  border-right: #e1f0fa 1px solid;
  padding-right: 20px;
  box-sizing: content-box;
  position: fixed;
  height: calc(100vh - 120px);
  overflow: auto;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  top: 85px;
  padding-top: 20px;
  z-index: 2;
  button {
    outline: none;
  }
  .typeChooser {
    background: #eeeeee;
    border-radius: 4px;
    padding: 2px;
    display: flex;
    button {
      background: none;
      outline: none;
      flex: 1;
      font-size: 0.9em;
      font-family: "Aller", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      padding: 5px 10px;
      color: #999;
      transition: all 0.3s;
      &.selected {
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
        color: var(--main);
        padding: 6px 10px 4px;
      }
    }
  }
  .searchBy {
    margin-top: 30px;
    label {
      font-family: "Aller", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      text-transform: uppercase;
      font-size: 0.8em;
      padding: 3px;
      position: absolute;
      margin-top: -10px;
      margin-left: 5px;
      background: #fff;
      transition: all 0.2s;
      z-index: 2;
      &[disabled] {
        margin-top: 10px;
        background: none;
        opacity: 0.5;
      }
    }
    input {
      margin-bottom: 20px;
      transition: all 0.3s;
      padding: 0.7rem 0.5rem 0.5rem;
      font-size: 0.9em;
      &:focus {
        outline: none;
        border: var(--main) 1px solid;
      }
    }
    .clear-search {
      font-size: 1.9rem;
      padding: 0;
      background: none;
      color: #aaa;
      position: absolute;
      margin-left: -33px;
      margin-top: 4px;
      &[disabled] {
        opacity: 0;
      }
    }
  }
  .search-button {
    width: 100%;
  }
  .clear {
    margin-top: -8px;
    margin-bottom: 15px;
    width: 100%;
    text-align: right;
    display: block;
    font-size: 0.8em;
    cursor: pointer;
  }
  .advanced {
    display: none;
    &.show {
      display: block;
    }
  }
  .advanced-link {
    font-size: 0.8em;
    display: block;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
  }
  .filters {
    margin-top: 20px;
    border-top: #e1f0fa 1px solid;
    padding-top: 10px;
    label {
      margin-top: 20px;
      font-family: "Aller", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      text-transform: uppercase;
      font-size: 0.9em;
      font-weight: bold;
    }
    small {
      opacity: 0.5;
      display: block;
    }
    .filter-link {
      display: block;
      cursor: pointer;
      color: #999;
      margin-bottom: 5px;
      &.selected {
        color: var(--main);
      }
      svg {
        position: relative;
        top: 3px;
      }
    }
  }
}
#search-results {
  float: right;
  width: calc(100% - 270px);
}
#startdate {
  z-index: 20;
}
.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 5;
  margin-top: -18px !important;
}
.input-range {
  width: calc(100% - 16px) !important;
  margin-left: 8px !important;
}
.input-range__slider {
  background: var(--main) !important;
  border: none !important;
  transition: none !important;
}
.input-range__track--active {
  background: var(--main) !important;
  transition: none !important;
}
.input-range__slider-container {
  transition: none !important;
}
#activation-page {
  width: 99%;
  max-width: 500px;
  margin: auto;
  text-align: center;
  form.ajax {
    pointer-events: none;
    opacity: 0.5;
  }
  form {
    input.code {
      width: 150px;
      text-align: center;
    }
  }
}
#user-leads {
  margin: 20px 0;
  h3 {
    margin-bottom: 20px;
  }
  .lead {
    border: #f0f0f0 1px solid;
    width: 250px;
    display: inline-block;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 5px;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    button {
      font-size: 12px;
      margin-top: 5px;
    }
    &.sold {
      border: #f52c2c88 2px solid;
      box-shadow: rgba(255, 0, 0, 0.5) 0px 1px 6px;
    }
    &.hot {
      border: #f5bf2c88 2px solid;
      box-shadow: #f5bf2c88 0px 1px 6px;
    }
    &.bad.hot {
      border: #00000088 2px solid;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 6px;
      opacity: 0.5;
    }
    .ribbon {
      position: absolute;
      background: #4caf50;
      color: #fff;
      top: 7px;
      right: -56px;
      transform: rotate(45deg);
      padding: 4px 60px;
    }
  }
}
.hotness {
  background: #607d8b20;
  padding: 5px;
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  color: #607d8b66;
  text-align: center;
  &.sold {
    background: #f44336;
    color: #fff;
  }
  &.hot {
    background: #ff9800;
    color: #fff;
  }
  &.cold {
    background: #9dd8ef;
    color: #fff;
  }
  &.hot.bad {
    background: #000;
  }
  &.rejected,
  &.doa {
    color: #bbb;
    background: #666;
  }
  &.accepted {
    background: #4caf50;
    color: #fff;
  }
  &.unverified {
    background: #000;
    color: #fff;
  }
  &.ping-1 {
    background: #a9f2a3;
    color:#666;
  }
  &.ping-2 {
    background: #4caf50;
    color:#FFF;
  }
}
.blurred {
  filter: blur(4px);
}
.exp .hotness {
  display: inline;
}
#pagination {
  text-align: center;
  margin: 10px;
  a {
    display: inline-block;
    cursor: pointer;
    margin: 0 10px;
    &.disabled {
      pointer-events: none;
      color: #999;
    }
  }
}
.chart-container .legend-dataset-text {
  font-size: 9px;
}
#lead-types-page {
  max-width: 900px;
  margin: auto;
  ol {
    margin: 20px 40px;
    text-align: left;
  }
  p {
    margin: 20px 0;
  }
}
.leads-buy {
  border: #f0f0f0 1px solid;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  border-radius: 5px;
  padding: 30px !important;
  margin: 10px;
  button {
    margin-top: 20px;
  }
}
.sms-conversation {
  height: 400px;
  overflow: auto;
  padding: 0 10px;
  .empty {
    text-align: center;
    margin: 50px 0;
  }
  article {
    clear: both;
    margin-bottom: 20px;
    span {
      font-size: 12px;
    }
    max-width: 300px;
  }
  article.in {
    float: left;
    p {
      background: #ddd;
      padding: 15px;
      border-radius: 15px;
    }
  }
  article.out {
    float: right;
    p {
      background: #00b7ff;
      color: #fff;
      padding: 15px;
      border-radius: 15px;
    }
    span {
      text-align: right;
      display: block;
    }
  }
}
.multi-select {
  height: 300px;
  overflow: auto;
  margin-bottom: 10px;
  border: #ddd 1px solid;
  border-radius: 4px;
  a {
    display: block;
    color: #888;
    border-bottom: #eee 1px solid;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    &:last-of-type {
      border: none;
    }
    svg {
      margin-right: 8px;
    }
    &.selected {
      font-weight: bold;
      color: #00b7ff;
    }
  }
}
.multi-select-dropdown {
  width: 100%;
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  border: #ddd 1px solid;
  margin-bottom: 0.7rem;
  font-size: 0.95rem;
  color: #606c76;
  font-family: "Aller";
  font-weight: 500;
  position: relative;
  max-height: 36px;
  .options {
    margin: 10px 0;
    max-height: 300px;
    overflow: auto;
  }
  a {
    display: block;
    padding: 5px 0;
  }
  .close {
    position: absolute;
    top: 7px;
    right: 5px;
    font-size: 20px;
  }
  .content {
    display: none;
  }
  .placeholder {
    cursor: pointer;
  }
  &.show {
    max-height: 1000px;
    .content {
      display: block;
    }
  }
}
.towed-link {
  display: flex;
  align-items: center;
  margin-left: 6px;
  position: absolute;
  z-index: 5;
  margin-top: -49px;
  svg {
    margin-right: 5px;
  }
}
.badge {
  background: #ef5350;
  color: #fff;
  padding: 1px 5px;
  border-radius: 50%;
}
#lead-plans {
  h2 {
    color: #fff;
    text-align: left;
  }
  p {
    text-align: left;
    color: #ccc;
    margin: 20px 0;
  }
}
.dark-page {
  background: #273035;
  background-image: url(/img/circles-bg.png);
  background-size: contain;
  background-repeat: repeat-y;
  width: 100%;
  .content {
    width: 95%;
    max-width: 1000px;
    margin: 30px auto;
    color: #fff;
    h1 {
      font-family: BebasNeue;
      font-size: 44px;
      text-align: center;
      span {
        color: #f29338;
      }
    }
    .title {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.ovals {
  margin: auto;
  padding: 50px 0;
  max-width: 990px;
}
.button-group {
  margin: 10px 0;
  a {
    display: inline-block;
    border: #ccc 1px solid;
    border-right: none;
    padding: 5px 15px;
    &:last-of-type {
      border-right: #ccc 1px solid;
      border-radius: 0 4px 4px 0;
    }
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &.active {
      background: var(--main);
      color: #fff;
      pointer-events: none;
    }
  }
}
#rss-generator {
  .type {
    label {
      display: inline-block;
      margin-right: 30px;
      margin-top: 10px;
    }
  }
  select {
    width: 200px;
    margin-top: 20px;
  }
  input {
    max-width: 700px;
  }
}
.gapi {
  padding: 10px;
  margin-top: 20px;
  background-color: #00b7ff24;
  border-radius: 4px;
}
.vehicle-summary {
  margin: 0 30px;
  th {
    &:first-of-type {
      width: 30px;
    }
  }
}
.commercial {
  margin-left: 5px;
  float: right;
}
#post-preview {
  font-family: Arial, Helvetica, sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: none;
  }
}
.image-left {
  float: left;
  margin-right: 10px;
}
.filters {
  display: flex;
  position: relative;
  select,
  .multi-select-dropdown {
    margin-right: 0.5%;
    // text-transform: capitalize;
    max-width: 250px;
  }
  img {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  @media (max-width: 700px) {
    display: block;
  }

  &.states {
    flex-wrap: wrap;
    margin-left:8px;
    @media (max-width: 700px) {
      display: none;
    }

    a {
      padding: 5px;
      display: inline-block;
      border: #ccc 1px solid;
      border-radius: 4px;
      margin-right: 4px;
      margin-bottom:4px;
      width: 40px;
      text-align: center;
      font-size: 13px;
      color: #999;
      transition: all 0.3s;
      &.active {
        background: var(--main);
        color: #fff;
        box-shadow: inset rgba(0, 0, 0, 0.5) 0px 3px 5px;
        padding: 6px 0 4px;
      }
    }
    margin-bottom: 20px;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &.statePrices {
    gap:5px;
    margin-bottom: 0;
    input {
      width: 120px;
    }
  }
}
.dot-menu {
  background: #fff;
  position: absolute;
  right: calc(50% - 10px);
  width: 250px;
  padding: 6px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
  opacity: 0;
  pointer-events: none;
  outline: none;
  margin-top: 3px;
  &.ping {
    width: 250px;
  }
  &.show {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }
  :before {
    content: " ";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    top: -4px;
    right: 6px;
    transform: rotate(45deg);
  }
  a {
    display: block;
    padding: 7px;
    text-align: left;
    color: #555;
    border-radius: 4px;
    &:hover {
      background: var(--main);
      color: #fff;
    }
  }
  span {
    border: #ccc 1px solid;
    font-weight: 100;
    display: block;
    margin: 7px 0;
    border-radius: 4px;
    strong {
      display: inline-block;
      background: #666;
      color: #fff;
      padding: 5px;
      text-align: right;
      width: 50%;
    }
  }
}
blockquote {
  padding: 10px;
  font-family: monospace;
  font-weight: normal;
  border: #ccc 1px solid;
  border-radius: 4px;
  margin: 5px 0;
  word-break: break-word;
  white-space: pre;
  overflow: hidden;
}
.selection-buttons {
  margin: 15px 0;
  button {
    padding: 5px 15px;
    font-size: 13px;
    margin-right: 5px;
  }
}
.jodit-wysiwyg {
  font-family: sans-serif;
  font-weight: normal;
}
.multi-sms-buttons {
  text-align: center;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  button {
    margin: 1px;
    padding: 8px 10px;
    flex: 1;
    font-size: 14px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.google-spends {
  input {
    width: 80px;
    text-align: center;
  }
  span {
    float: left;
    display: block;
    text-align: center;
    margin: 5px;
    padding: 10px;
  }
  .footer {
    clear: both;
  }
}
.swal2-checkbox {
  input {width: auto;}
}
.lead-status-holder {
  .tooltip {
    position:absolute;
    background-color: #FFF;
    bottom:20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px;
    padding:10px;
    border-radius: 5px;
    z-index:2;
    width:500px;
    left:-230px;
    transition: all 0.3s;
    opacity: 0;
    pointer-events: none;
    &.show {
      display: block;
      bottom:40px;
      opacity: 1;
      pointer-events: all;
    }
    .close {
      font-size:16px;
      padding:0;
      float:right;
      width: auto;
    }
  }

}
.lead-status {
  background-color: #ff9800;
  display: flex;
  padding:5px;
  gap:5px;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: row-reverse;
  position: relative;
  &.bad.hot {
    background-color: #000;
  }
  &.sold {
    background-color: #ef5350;
  }
  span {
    display: block;
    background-color: #FFF;
    height: 17px;
    border:#777 1px solid;
    flex:1;
    width: 14px;
    &.green {
      background-color: #4caf50;
    }
    &.blue {
      background-color: #00b7ff;
    }
    &.black {
      background-color:#031e29;
    }
  }
}

/* AdminLeadsTable */
.populate-rejection {
  margin-bottom: 0.8rem;
  a {
    span {
      margin-left: 0.8rem;
      color: #606c76;
    }
  }
}

.reject-notes {
  position: relative;
}

.loading-dots {
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #606c76;
  color: #606c76;
  position: relative;
  animation: dot-flashing 0.6s infinite linear alternate;
  animation-delay: 0.3s;

  &:before, &:after {
    position: absolute;
    top: 0;
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #606c76;
    color: #606c76;
    animation: dot-flashing 0.6s infinite alternate;
  }

    &:before {
      left: -10px;
      animation-delay: 0s;
    }

    &:after {
      left: 10px;
      animation-delay: 0.6s;
    }

  &__container {
    position: absolute;
    top: 32px;
    left: 22px;
    display: flex;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #606c76;
  }
  50%, 100% {
    background-color: rgb(229, 235, 246);
  }
}
