#pricing {
	width: 100%;
	text-align: center;

	#pricing-first {
		background-color: $primary-gray;
		min-height: 850px;
		padding-top: 20px;
		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 150px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -20px;
			background: inherit;
			z-index: -1;
		}
		#plans {
			.box-holder {
				z-index: 3;
				h2 {
					color: #ffffff;
				}
			}
			padding-bottom: 120px;
		}
	}

	/* @media only screen and (min-width: 600px) {
    #pricing-first {
      min-height: 900px;
    }
  }

  @media only screen and (min-width: 960px) {
    #pricing-first {
      min-height: 950px;
    }
  }

  @media only screen and (min-width: 1200px) {
    #pricing-first {
      min-height: 1000px;
    }
  }

  @media only screen and (min-width: 1500px) {
    #pricing-first {
      min-height: 1050px;
    }
  } */
	.pricing-content {
		position: relative;
		z-index: 2;
		max-width: 980px;
		margin: 0 auto;

		&-second {
			display: flex;
			flex-direction: column;
			@media only screen and (min-width: 1005px) {
				flex-direction: row;
				img {
					max-width: 100%;
				}
			}
			&-content {
				display: flex;
				flex-direction: column;
			}
		}

		&-third {
			&-one {
				display: flex;
				flex-direction: column;
				@media only screen and (min-width: 1005px) {
					flex-direction: row;
					justify-content: space-evenly;
				}
			}
			&-two {
				display: flex;
				flex-direction: column;
				@media only screen and (min-width: 1005px) {
					flex-direction: row-reverse;
					justify-content: space-evenly;
					padding-bottom: 150px;
				}
			}
		}

		&-fourth {
			padding-top: 140px;
			padding-bottom: 120px;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media only screen and (min-width: 1005px) {
				flex-direction: row;
				justify-content: space-evenly;
				padding-top: 0;
				padding-bottom: 0;
			}
			&-img {
				width: 353px;
				@media only screen and (max-width: 1004px) {
					display: none;
					z-index: 3;
				}
			}
		}
	}

	#pricing-second {
		width: 100%;
		position: relative;
		/* min-height: 690px; */
		background-image: linear-gradient(to left, #09aed0, #07a5ca);
		margin-bottom: 120px;

		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			background: inherit;
			z-index: -1;
		}

		&:before {
			z-index: 1;
			top: -22px;
			transform-origin: top left;
			transform: skewY(-14deg);
		}

		&:after {
			bottom: -400px;
		}

		@media (min-width: 900px) {
			min-height: auto;
		}
		.myaccident-complete {
			width: 200px;
			margin: 0 auto;
			margin-top: 20px;
			padding-top: 30px;
			font-family: BebasNeue;
			font-size: 50px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.9;
			letter-spacing: normal;
			text-align: center;
			color: #ffffff;
		}
		.my-accident-pro {
			padding-top: 7px;
			padding-bottom: 50px;
			width: fit-content;
			margin: auto;
			height: 22px;
			font-family: Aller;
			font-size: 17px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.my-accident-reports {
			margin: 0 auto;
			margin-bottom: -16px;
			width: 180px;
			font-family: Aller;
			font-size: 17px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #ffffff;
		}

		.report-img {
			margin: 0 auto;
			margin-bottom: -16px;
		}
		.pdf-report-img {
			margin: 0 auto;
			width: 317px;
			height: 449px;
			object-fit: contain;
		}
	}

	#pricing-third {
		width: 100%;
		position: relative;
		padding-top: 36px;
		/* min-height: 690px; */
		/* background-image: linear-gradient(to left, #09aed0, #07a5ca); */
		margin-bottom: 120px;

		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			background: inherit;
			z-index: -1;
		}

		&:before {
			z-index: 1;
			top: -22px;
			transform-origin: top left;
			transform: skewY(-14deg);
		}

		&:after {
			bottom: 0;
			transform-origin: left bottom;
			transform: skewY(14deg);
		}

		@media (min-width: 900px) {
			min-height: auto;
		}
		.myaccident-pro {
			width: 213px;
			margin: 0 auto;
			margin-bottom: 30px;
			font-family: BebasNeue;
			font-size: 50px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.9;
			letter-spacing: normal;
			text-align: center;
			color: #666666;
			&-small {
				width: 335px;
				margin: 0 auto;
				margin-bottom: 20px;
				font-family: Aller;
				font-size: 17px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: #aaaaaa;
				@media only screen and (min-width: 1005px) {
					text-align: left;
					margin-bottom: 10px;
					width: 413px;
				}
			}
			@media only screen and (min-width: 1005px) {
				width: fit-content;
			}
		}

		.pdf-report-img {
			width: 375px;
			height: 473px;
			margin: 0 auto;
			margin-bottom: -16px;
			margin-top: -20px;
			padding-bottom: 22px;
			/* box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.84); */
			/* border: solid 12px #ffffff; */
			&-two {
				height: 478px;
			}
		}
	}

	#pricing-fourth {
		width: 100%;
		position: relative;
		/* min-height: 690px; */
		background-color: $primary-gray;
		margin-bottom: 120px;

		&:before,
		&:after {
			content: "";
			width: 100%;

			position: absolute;
			left: 0;
			right: 0;
			background: inherit;
			z-index: -1;
		}

		&:before {
			height: 600px;
			z-index: 1;
			top: -22px;
			transform-origin: top right;
			transform: skewY(14deg);
		}

		&:after {
			height: 100%;
			bottom: 0;
			transform-origin: left bottom;
			transform: skewY(14deg);
		}

		@media (min-width: 900px) {
			min-height: auto;
		}
		.myaccident-report {
			margin-bottom: 26px;
			text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.18);
			font-family: BebasNeue;
			font-size: 50px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.1;
			letter-spacing: normal;
			text-align: center;
			color: #f29338;
			&-small {
				margin: 0 auto;
				margin-bottom: 20px;
				width: 334px;
				font-family: Aller;
				font-size: 17px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: #ffffff;
			}
		}
	}
	#pricing-fifth {
		width: 100%;
		position: relative;
		/* min-height: 690px; */
		/* background-image: linear-gradient(to left, #09aed0, #07a5ca); */
		margin-bottom: 120px;

		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			/* position: absolute; */
			background: inherit;
			z-index: -1;
		}

		&:before {
			z-index: 1;
			top: 0;
			transform-origin: top left;
			transform: skewY(-14deg);
		}

		&:after {
			bottom: 0;
			transform-origin: left bottom;
			transform: skewY(14deg);
		}

		@media (min-width: 900px) {
			min-height: auto;
		}

		.myaccident-pro-search {
			padding-top: 70px;
			padding-bottom: 14px;
			font-family: BebasNeue;
			font-size: 50px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.1;
			letter-spacing: normal;
			text-align: center;
			color: #f39228;
			@media only screen and (min-width: 1005px) {
				padding-top: 240px;
			}
			&-top {
				margin: 0 auto;
				margin-top: 35px;
				font-family: BebasNeue;
				font-size: 30px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.83;
				letter-spacing: normal;
				text-align: center;
				color: #666666;
				@media only screen and (min-width: 1005px) {
					text-align: left;
				}
			}
			&-bottom {
				margin: 0 auto;
				width: 335px;
				font-family: Aller;
				font-size: 17px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: #aaaaaa;
				@media only screen and (min-width: 1005px) {
					text-align: left;
				}
				&-one {
					@media only screen and (min-width: 1005px) {
						width: 985px;
					}
				}
			}
			&-container {
				display: flex;
				flex-direction: column;
				@media only screen and (min-width: 1005px) {
					flex-direction: row;
					justify-content: space-evenly;
				}
			}
		}
	}

	.pdf-report-img-wrapper {
		position: relative;
		width: 375px;
		margin: auto;

		.circle {
			position: absolute;
			width: 34px;
			height: 34px;
			border: 4px solid rgba($primary-orange, 0.9);
			border-radius: 50%;

			.line {
				display: none;
			}

			&.first {
				top: 25px;
				left: 106px;
			}

			&.second {
				top: 74px;
				left: 193px;
			}

			&.third {
				bottom: 65px;
				left: 135px;
			}

			&.secondImg-first {
				top: 95px;
				right: 105px;
			}

			&.secondImg-second {
				bottom: 100px;
				left: 145px;
			}

			@media (min-width: 1004px) {
				width: 10px;
				height: 10px;
				border: none;
				background-color: $primary-orange;

				.line {
					display: block;
					position: relative;
					height: 5px;
					width: 40px;
					background-color: $primary-orange;

					.circle-text {
						position: absolute;
						bottom: -15px;
						font-family: "BebasNeue";
						font-size: 25px;
						line-height: 25px;
						color: $gray;
					}

					.circle-outer {
						position: absolute;
						top: -9px;
						width: 22px;
						height: 22px;
						border: 4px solid $primary-orange;
						border-radius: 50%;
					}
				}

				&.first {
					top: 37px;
					left: 116px;
				}

				&.second {
					top: 79px;
					left: 169px;
				}

				&.third {
					bottom: 77px;
					left: 168px;
				}

				&.secondImg-first {
					top: 91px;
					right: 119px;
				}

				&.secondImg-second {
					bottom: 120px;
				}
			}
		}

		.inner-circle {
			width: 20px;
			height: 20px;
			background-color: rgba($primary-orange, 0.9);
			border-radius: 50%;
			position: relative;
			margin: 3px;

			@media (min-width: 1004px) {
				position: relative;
				border-radius: 0;
				height: 5px;

				&.first {
					width: 53px;
					right: 52px;
					transform-origin: right bottom;
					transform: skewY(-43deg);

					& + .line {
						top: 25px;
						right: 88px;
						width: 39px;

						.circle-outer {
							left: -20px;
						}

						.circle-text {
							left: -69px;
							width: 40px;
							bottom: -30px;
						}
					}
				}

				&.second {
					width: 110px;
					right: 108px;
					transform-origin: right bottom;
					transform: skewY(-34deg);

					& + .line {
						top: 50px;
						right: 140px;
						width: 35px;

						.circle-outer {
							left: -20px;
						}

						.circle-text {
							left: -77px;
							width: 50px;
							bottom: -30px;
							text-align: right;
						}
					}
				}

				&.third {
					width: 36px;
					right: 34px;
					transform-origin: right bottom;
					transform: skewY(-45deg);

					& + .line {
						top: 12px;
						right: 72px;
						width: 42px;
						height: 4px;

						.circle-outer {
							left: -20px;
						}

						.circle-text {
							left: -180px;
							width: auto;
							bottom: -15px;
						}
					}
				}

				&.secondImg {
					&.first {
						width: 36px;
						right: -3px;
						transform-origin: left top;
						transform: skewY(40deg);

						& + .line {
							top: 6px;
							right: -42px;
							width: 38px;
							height: 5px;

							.circle-outer {
								left: initial;
								right: -20px;
							}

							.circle-text {
								left: 66px;
								bottom: -25px;
								width: 85px;
								text-align: left;
							}
						}
					}

					&.second {
						width: 70px;
						right: -2px;
						transform-origin: left top;
						transform: skewY(50deg);

						& + .line {
							top: 59px;
							right: -75px;
							width: 38px;
							height: 4px;

							.circle-outer {
								left: initial;
								right: -20px;
							}

							.circle-text {
								left: 68px;
								bottom: -25px;
								width: 165px;
								text-align: left;
							}
						}
					}
				}
			}
		}
	}
	h1 {
		font-family: "BebasNeue";
		color: #fff;
		font-size: 40px;
	}
}
.oval {
	margin: 0 auto;

	margin-bottom: 20px;
	width: 67px;
	height: 67px;
	padding: 7px 0 0 22px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	background-color: #f29338;
	border-radius: 50%;
	&-number {
		width: 20px;
		height: 57px;
		font-family: BebasNeue !important;
		font-size: 48px !important;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
	}
}
.my-accident-plus {
	margin: 0 auto;
	width: 20px;
	padding-bottom: 10px;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
	font-family: BebasNeue;
	font-size: 48px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}
