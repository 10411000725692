#licence-agreement {

	.Agreement {
		.agreement-title {
			font-size: 35px;
			font-weight: $light;
			text-align: left;
		}
	
		h3 {
			font-size: 25px;
			line-height: 27px;
			margin-top: 40px;
			&:first-of-type {
				margin-top: 0;
			}
		}
	
		p {
			font-size: 16px;
			margin-bottom: 20px;
			font-weight: $bold;
			color: $secondary-text-gray;
			text-align: left;
		}
	
		a {
			color: $primary-orange;
		}
	
		.white-text {
			color: $white;
			margin-bottom: 0;

			&.last {
				margin-bottom: 20px;
			}
		}
	}
}
