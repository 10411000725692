.Footer {
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 71px;
	font-family: "Aller";
	font-size: 12px;
	background-color: $secondary-gray;
	color: $footer-text-color;
	&.loggedin {
		background: none;
		padding-left: 120px;
		opacity: 0.7;
	}
	.Footer__right-text {
		margin-bottom: 7px;

		@media (min-width: 900px) {
			margin-bottom: 0;
			margin-right: 20px;
		}
	}

	.Footer__link {
		margin-right: 20px;
		color: $footer-text-color;

		&:after {
			content: "|";
			position: relative;
			left: 10px;
		}

		&:last-of-type {
			margin-right: 0;

			&:after {
				content: none;
			}
		}
	}

	@media (min-width: 900px) {
		height: 50px;
		flex-direction: row;
	}
}
