#signIn,
#forgotPass,
#signUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: $primary-gray;
  #plans {
    input {
      background: #fff !important;
    }
  }
  form {
    background-color: $primary-gray;
  }

  .SignIn__content,
  .ForgotPass__content,
  .SignUp__content {
    padding: 0 20px;
    text-align: center;

    @media (min-width: 900px) {
      min-width: 850px;
      max-width: 980px;
      margin: auto;
    }
  }

  .SignUp__content {
    padding: 20px 20px 100px;

    .stripe_input {
      .ElementsApp,
      .ElementsApp .InputElement {
        color: $white !important;
      }
    }
    .error {
      transform: translateY(-15px);
      text-align: left;

      &.no-transform {
        transform: initial;
      }
    }

    fieldset:last-child {
      input {
        margin-bottom: 30px;
      }
    }

    h3 {
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 20px;
      text-align: left;
    }

    .phone-text {
      margin-bottom: 28px;
      text-align: left;
      p {
        color: $secondary-text-gray;
      }
    }

    .sms-text {
      font-size: 16px;
      margin-bottom: 5px;
      text-align: left;
      color: $white;
    }

    .select {
      margin-bottom: 30px;
    }

    .css-yk16xz-control {
      border: 3px solid rgba($gray, 0.5);
      background-color: $primary-gray;
      border-radius: 0;
    }

    .css-1okebmr-indicatorSeparator {
      background-color: rgba($gray, 0.5);
      width: 2px;
    }

    .css-1hwfws3 {
      height: 50px;
      font-size: 14px;
      border-radius: 0;
      border-right: 0;
      color: $white;
    }

    .css-26l3qy-menu {
      z-index: 2;
    }

    .css-1g48xl4-IndicatorsContainer {
      background-color: $primary-gray;
      border: 0 !important;
    }

    .business-address-article {
      margin-top: 10px;
    }

    .auth-btn {
      margin-top: 0;
      margin-bottom: 17px;

      &.disabled {
        background-color: $secondary-text-gray;
      }
    }

    .SignUp__step2 {
      background-color: $primary-gray;

      #plans {
        margin-bottom: 15px;
        .tabs a {
          font-size: 20px;
          font-weight: $light;
          padding: 2px 0;

          &:nth-of-type(2) {
            border-left: $primary-orange 2px solid;
            border-right: $primary-orange 2px solid;
          }
        }
      }
    }

    .stripe_secure_logo {
      p {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: $bold;
        color: $secondary-text-gray;
      }
    }

    .stripe_cards_logo {
      margin-bottom: 3px;
      img {
        height: 30px;
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .stripe_company_info {
      p,
      a {
        font-size: 12px;
        font-weight: $bold;
        color: $secondary-text-gray;
      }

      p.flex {
        display: flex;
        flex-direction: column;

        @media (min-width: 900px) {
          display: block;
        }
      }

      a {
        color: $primary-orange;
      }
    }

    .send-code {
      display: flex;
      align-items: baseline;
      height: 50px;
      margin-bottom: 15px;
    }

    .checkbox-wrapper {
      margin: 30px 0 30px;
    }

    .checkbox {
      display: flex;
      margin-bottom: 25px;
      &:first-child {
        margin-top: 27px;
      }
      &:last-child {
        margin-bottom: 28px;
      }
    }

    .agreement-text {
      width: calc(100% - 30px);
      padding-left: 15px;
      font-family: "BebasNeue";
      font-size: 18px;
      line-height: 18px;
      text-align: left;
      color: $white;
    }

    .billing-text {
      margin-bottom: 30px;
      color: $secondary-text-gray;
    }

    .footer {
      margin-top: 0;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
  }

  .SignIn__circle-shape,
  .SignUp__circle-shape,
  .ForgotPass__circle-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 680px;
    pointer-events: none;

    circle {
      stroke-width: 130;
      stroke-opacity: 0.4;
    }

    @media (min-width: 900px) {
      circle {
        stroke-width: 90;
      }
    }
  }

  .SignIn__form-wrapper,
  .ForgotPass__form-wrapper {
    @media (min-width: 768px) {
      width: 58%;
    }
  }

  .SignIn__forgotpass-text {
    text-align: left;

    a {
      font-size: 15px;
      font-weight: $bold;
      font-size: 15px;
      color: $primary-orange;
    }
  }

  button,
  label,
  input {
    font-family: "Aller";
  }

  h1,
  h3 {
    font-family: "BebasNeue";
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25px;
    color: $white;
    font-weight: $light;

    &.SignUp__title {
      margin-bottom: 10px;
      text-align: left;
    }

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  fieldset {
    position: relative;

    &:last-child {
      input {
        margin-bottom: 15px;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  label {
    position: absolute;
    top: -5px;
    left: 20px;
    padding: 0 6px;
    font-size: 13px;
    z-index: 1;
    color: $white;
    text-transform: uppercase;
    font-weight: $bold;
    background-color: $primary-gray;

    &.static {
      position: static;
    }
  }

  input,
  .stripe_input {
    height: 50px;
    margin-bottom: 30px;
    font-size: 16px;
    border-radius: 0;
    border: 3px solid rgba($gray, 0.5);
    background-color: $primary-gray;
    color: $white;

    &:focus {
      background-color: $primary-gray !important;
      outline: 0;
    }

    &:invalid {
      box-shadow: none;
    }

    &:-moz-submit-invalid {
      box-shadow: none;
    }

    &:-moz-ui-invalid {
      box-shadow: none;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px $primary-gray inset;
    -webkit-text-fill-color: $white;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px $primary-gray inset;
    -webkit-text-fill-color: $white;
  }

  .auth-btn {
    padding: 14.5px 10px;
    margin-top: 18px;
    font-size: 14px;
    font-weight: $bold;
    width: 100%;
    background-color: $primary-orange;
    color: $white;
    text-transform: initial;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);

    &.hidden {
      display: none;
    }

    &.send-sms {
      width: 85px;
      height: 50px;
      border: 2px solid $gray;
      border-left: 0;
      border-radius: 0;
    }

    @media (min-width: 768px) {
      align-self: flex-start;
      width: auto;
      min-width: 123px;

      &.forgot-pass-btn {
        margin-top: 30px;
      }
    }
  }

  @media (min-width: 900px) {
    .form-input-group {
      display: flex;
      justify-content: space-between;

      &.three-bottom fieldset {
        width: calc(33% - 15px);
      }
    }

    .SignUp__content {
      padding: 15px 0 60px;

      #plans {
        margin-bottom: 28px;
      }

      fieldset {
        width: calc(50% - 15px);
      }

      .auth-btn {
        &.back {
          margin-right: auto;
        }

        &.next {
          margin-left: auto;
        }
      }

      .card-adress-wrapper {
        display: flex;
      }

      .stripe_secure_logo {
        margin-top: 30px;
      }

      .stripe_secure_logo,
      .stripe_cards_logo,
      .stripe_company_info {
        text-align: left;
      }

      .billing-text {
        text-align: left;
      }

      .agreement-text {
        font-size: 25px;
        line-height: 30px;
      }

      .SignUp__step3 {
        .card-info,
        .billing-address {
          width: 50%;
        }

        .card-info {
          margin-right: 30px;
        }

        .form-input-group {
          fieldset {
            width: 100%;
          }

          &.two-fields {
            display: flex;
            justify-content: space-between;

            fieldset {
              width: calc(50% - 15px);
            }
          }
        }
      }

      .send-code-wrapper,
      .sms-code-wrapper {
        width: 50%;
      }

      .send-code-wrapper {
        margin-right: 30px;
      }

      .send-sms-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
