.HomePage {
	overflow: hidden;
	width: 100%;

	.HomePage__back-to-top {
		position: absolute;
		left: 50%;
		bottom: 107px;
		transform: translateX(-50%);
		cursor: pointer;

		@media (min-width: 900px) {
			position: absolute;
			transform: initial;
			left: 20px;
			bottom: 70px;
		}
	}

	.slider-btn-wrapper {
		transform: translateY(-20px);

		.slider-btn {
			font-size: 23px;
			padding: 0;
			outline: 0;
			background-color: transparent;

			&.active {
				color: $primary-orange;
				pointer-events: none;
			}
		}

		span {
			font-size: 23px;
			color: $white;
		}
	}

	h1,
	h2,
	h3,
	button,
	.secondImg-text {
		font-family: "BebasNeue";
	}

	h3 {
		font-weight: $light;
	}

	p,
	ul {
		font-family: "Aller";
		font-size: 17px;
	}

	.HomePage__content {
		position: relative;
	}

	.HomePage__top-background {
		z-index: -1;
		min-height: 690px;
		background-color: $primary-gray;
		padding-left: 20px;
		padding-right: 20px;
		@media (min-width: 900px) {
			min-height: 900px;
		}
		padding-top: 43px;
		&:after {
			content: "";
			width: 100%;
			height: 400px;
			position: absolute;
			top: 500px;
			background: inherit;
			z-index: 2;
			bottom: 0;
			transform-origin: left bottom;
			transform: skewY(-14deg) translateX(-20px);
			@media (max-width: 1165px) {
				top: 630px;
			}
			@media (max-width: 1010px) {
				top: 700px;
			}
			@media (max-width: 899px) {
				top: 1035px;
			}
			@media (max-width: 803px) {
				top: 1085px;
			}
			@media (max-width: 700px) {
				top: 1510px;
			}
			@media (max-width: 664px) {
				top: 1540px;
			}
		}
	}

	.HomePage__top-background-content {
		display: flex;
		flex-direction: column-reverse;
		margin: auto;
		@media (min-width: 900px) {
			flex-direction: row;
			justify-content: center;
			max-width: 1200px;
			margin: auto;
			padding: unset;
		}
		.box-holder {
			min-width: 289px;
			max-width: 289px;
			height: 100%;
			z-index: 3;

			@media (max-width: 899px) {
				margin: auto;
			}
		}
		.box {
			border-radius: 8px;
			box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.7);
			background-color: #ffffff;
			overflow: hidden;
			position: relative;
			.top {
				height: 127px;
				width: 289px;
				background-image: linear-gradient(to left, #0b9db3, #00617d);
				font-family: BebasNeue;
				color: #ffffff;
				text-align: center;
				font-size: 22px;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				z-index: 0;
				width: 100%;
				min-height: inherit;
				-webkit-clip-path: polygon(685px 0, -400px 0, 50% 100%);
				clip-path: polygon(685px 0, -400px 0, 50% 100%);
				padding-bottom: 127px;
				.price {
					font-size: 35px;
					padding-top: 10px;
				}
				.line {
					width: 180px;
					height: 3px;
					background-color: #f29338;
					margin: 2px auto 10px;
					&-2 {
						border-bottom: none;
					}
				}
				.limited-offer {
					position: absolute;
					top: 15px;
					right: -47px;
					width: 160px;
					height: 30px;
					text-align: center;
					transform: rotate(45deg);
					font-family: BebasNeue;
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #ffffff;
					background-color: #f29338;
					padding: 7px 10px 4px 10px;
					-webkit-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.25);
					-moz-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.25);
					box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.25);
				}

				span {
					font-family: Aller;
					font-size: 20px;
					font-weight: bold;
				}
			}
			/* .top2 {
				background-image: linear-gradient(to left, #0db0c9, #017fa4);
			}
			.top3 {
				background-image: linear-gradient(to left, #0b9db3, #00617d);
			} */
			.triangle {
				max-width: 100%;
			}
		}
		.li {
			margin: 7.5px 20px 7.5px 20px;
			display: flex;
			font-size: 17px;
			color: #666666;
			font-weight: bold;
			text-align: left;
			.super {
				font-size: 9px;
				font-weight: bold;
				position: relative;
				top: -8px;
				left: -3px;
			}
			small {
				display: block;
				font-size: 12px;
				color: #aaaaaa;
				margin-top: 5px;
				max-width: 146px;
			}
			svg {
				margin-right: 8.5px;
			}

			.price-wrapper {
				display: flex;
				flex-direction: row;
				@media only screen and (max-width: 400px) {
					flex-direction: column;
				}
			}

			.price {
				font-family: BebasNeue;
				font-size: 25px;
				font-weight: normal;
				color: #f29338;
				display: flex;
				margin: auto;
				margin-top: 12px;
				margin-bottom: -5px;
				width: fit-content;
				span {
					font-size: 13px;
					font-weight: bold;
				}

				&-black {
					color: #777777;
				}
			}
		}
		.line2 {
			height: 1px;
			background-color: #eeeeee;
			margin: 10px 20px;
		}
		button {
			width: 178px;
			height: 45px;
			border-radius: 5px;
			box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);
			background-color: #f29338;
			display: block;
			margin: 15px auto;
			text-transform: none;
		}
		.LIMITED-OFFER {
			width: 130px;
			text-align: center;
			transform: rotate(45deg);
			font-family: BebasNeue;
			font-size: 16px;
			color: #ffffff;
			position: absolute;
			background-color: #f29338;
			box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);
			top: 25px;
			right: -33px;
		}
		.HomePage__title-desc-wrapper {
			text-align: center;
			padding-top: 7px;
			z-index: 3;
			@media (max-width: 899px) {
				margin: auto;
				margin-bottom: 60px;
			}
			.HomePage__main-title {
				font-family: BebasNeue;
				font-size: 50px;
				line-height: 0.96;
				color: #ffffff;
				margin-bottom: 63px;
				@media (max-width: 899px) {
					max-width: calc(100vw - 20px);
					margin: auto;
					margin-bottom: 63px;
				}
			}
			@media (min-width: 900px) {
				text-align: left;
				padding-top: 7px;

				&.white-bkg {
					padding-right: 100px;
				}
			}
		}

		.HomePage__reasons {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			@media (max-width: 523px) {
				justify-content: center;
			}
			@media (min-width: 900px) {
				justify-content: space-evenly;
			}

			@media (min-width: 853px) and (max-width: 900px) {
				margin-left: 11%;
			}
			@media (min-width: 824px) and (max-width: 853px) {
				margin-left: 60px;
			}
			@media (min-width: 740px) and (max-width: 823px) {
				margin-left: 40px;
			}

			@media (min-width: 701px) and (max-width: 740px) {
				margin-left: 20px;
			}

			justify-content: flex-start;
			&_reason {
				flex-basis: 33%;
				max-width: 200px;
				text-align: center;
				margin: 20px;

				@media (max-width: 1165px) {
					margin-bottom: 20px;
				}
				@media (max-width: 900px) {
					margin-bottom: 40px;
				}
				@media (max-width: 803px) {
					flex-basis: 50%;
					max-width: calc(50% - 40px);
				}
				@media (max-width: 700px) {
					flex-basis: 100%;
					max-width: calc(100% - 40px);
				}
				@media (max-width: 563px) {
					margin-right: 20px;
					flex-basis: 100%;
					max-width: calc(100vw - 40px);
				}
				.reason_image {
					margin-bottom: 25px;
					height: 40px;
					&_clock {
						width: 40px;
					}
					&_phone {
						width: 23px;
					}
					&_id {
						width: 36px;
					}
					&_graph {
						width: 46px;
					}
				}
				.reason_text {
					width: 200px;
					font-family: Aller;
					font-size: 17px;
					color: #ffffff;
					@media (max-width: 803px) {
						width: 300px;
					}
					@media (max-width: 700px) {
						width: 250px;
						margin: auto;
					}
					@media (max-width: 563px) {
						margin: auto;
						flex-basis: 100%;
					}
					&_small {
						font-size: 10px;
						display: flex;
						text-align: left;
						margin-top: 18px;
						width: 200px;
						@media (max-width: 803px) {
							width: 300px;
						}
						@media (max-width: 700px) {
							width: 250px;
						}
					}
				}
			}
		}
	}

	.HomePage__blue-background {
		position: relative;
		min-height: 690px;
		background-image: linear-gradient(to left, #0fc2dd, #0295bf);
		display: flex;
		justify-content: center;

		&:before,
		&:after {
			content: "";
			width: calc(100vw + 13px);
			left: -13px;
			height: 24.932800284vw;
			position: absolute;
			background: url("../img/gradient-triangle1.svg");
			z-index: -1;
			background-size: 100% 100%;
		}

		&:before {
			z-index: 1;
			top: -24.7vw;
			transform-origin: top left;
		}

		&:after {
			bottom: 3px;
			transform-origin: left bottom;
			transform: scaleY(-1);
		}
		.all-reports {
			margin-top: 16px;
			text-align: center;
			height: 24px;
			font-family: Aller;
			font-size: 10px;
			color: #ffffff;
		}

		@media (min-width: 900px) {
			max-height: 750px;
			min-height: auto;
			padding-top: 130px;
			.HomePage__title-search-wrapper {
				width: 50%;

				.all-reports {
					text-align: right;
				}
			}
		}
		@media (min-width: 985px) {
			max-height: 750px;
			padding-top: 210px;
		}
		@media (min-width: 1010px) {
			max-height: 600px;
			padding-top: 110px;
		}
		@media (min-width: 1166px) {
			max-height: 500px;
			padding-top: 20px;
		}
		@media (min-width: 1500px) {
			max-height: 540px;
			padding-top: 110px;
		}
		.HomePage__blue-background-content {
			margin-top: 2vw;
			display: flex;
			justify-content: space-between;
			z-index: 2;
			position: relative;
			text-align: center;
			@media (min-width: 900px) {
				max-width: 1200px;
				margin: auto;
				margin-right: 40px;
				margin-left: 40px;
			}

			@media (max-width: 1010px) {
				padding: 20px 20px 0px 20px;
				max-width: 1200px;
				margin: auto;
			}

			@media (max-width: 969px) {
				padding: 50px 20px 0px 20px;
				max-width: 1200px;
				margin: auto;
			}

			@media (max-width: 900px) {
				padding: 180px 20px 0px 20px;
				margin: 20px auto;
			}

			@media (max-width: 664px) {
				margin: 40px auto;
			}
			@media (max-width: 500px) {
				margin: 20px auto 40px auto;
			}

			.HomePage__circle-shape {
				position: absolute;
				top: -140px;
				right: -20px;

				@media (min-width: 950px) {
					right: -215px;
					width: 200%;
				}
			}
			.phone-img {
				display: none;
				transform: translate(10%, -100px);
				@media (min-width: 900px) {
					display: block;
					width: 450px;
					height: 685.5px;
					object-fit: contain;
					margin-top: 100px;
					transform: translate(-10px, -150px);
				}
				@media (min-width: 976px) {
					transform: translate(-10px, -90px);
				}
				@media (min-width: 985px) {
					width: 500px;
					height: 761.6px;
					margin-top: 40px;
					transform: translate(-10px, -100px);
				}
				@media (min-width: 1076px) {
					margin-top: 20px;
					transform: translate(10px, -100px);
				}

				@media (min-width: 1166px) {
					width: 550px;
					height: 837.7px;
					margin-top: 20px;
				}

				@media (min-width: 1300px) {
					width: 650px;
					height: 990px;
					margin-top: 20px;
					transform: translate(-30px, -160px);
				}

				@media (min-width: 1485px) {
					margin-top: -40px;
					transform: translate(-30px, -110px);
				}
			}
			.HomePage__title-search-wrapper {
				position: relative;
				@media (min-width: 900px) {
					margin-right: 40px;
				}
				@media (min-width: 975px) {
					margin-right: 60px;
				}
				@media (min-width: 920px) {
					margin-right: 40px;
				}
				@media (min-width: 975px) {
					margin-right: 60px;
				}
			}
		}
	}

	.laptop-img {
		display: none;

		@media (min-width: 900px) {
			display: block;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -115px);
			z-index: 2;
		}
	}

	.HomePage__title {
		font-size: 44px;
		font-weight: $light;
		margin-bottom: 20px;

		&.search-title {
			color: $white;

			@media (min-width: 900px) {
				text-align: right;
			}
		}

		&.orange-title {
			width: 524px;
			max-width: calc(100vw - 120px);
			text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.35);
			font-family: BebasNeue;
			font-size: 50px;
			line-height: 0.84;
			color: #ffffff;
			text-align: left;
			span {
				color: $primary-orange;
			}
			@media (max-width: 960px) {
				text-align: center;
				margin: auto;
				margin-bottom: 89px;
			}
		}
	}

	.HomePage__main-title {
		color: $white;

		span {
			color: $primary-orange;
		}

		&.gray-title {
			color: $gray;
		}
	}

	.HomePage__description {
		line-height: 20px;
		color: $white;

		&.text-shadow {
			margin-bottom: 44px;
			text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.35);
		}

		&.search-description {
			margin-bottom: 20px;

			&:last-of-type {
				margin-bottom: 55px;
			}
			@media only screen and (max-width: 900px) {
				max-width: calc(100vw - 240px);
			}
			@media only screen and (max-width: 577px) {
				max-width: calc(100vw - 100px);
			}
			@media only screen and (max-width: 450px) {
				max-width: calc(100vw - 50px);
			}

			@media (min-width: 900px) {
				text-align: right;
			}
		}

		&.gray-text {
			margin-bottom: 20px;
			color: $secondary-text-gray;
		}
	}

	.HomePage__price-block {
		max-width: 320px;
		margin: auto;
		padding: 0 15px;
		text-align: center;
		border-radius: 8px;

		@media (min-width: 900px) {
			max-width: auto;
			min-width: 308px;
		}
	}

	.HomePage__price-block-inner {
		border-radius: 8px;
		box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.7);

		@media (min-width: 900px) {
			min-width: 308px;
		}
	}

	.HomePage__price-block-title {
		font-size: 20px;
		padding: 8px 0;
		text-transform: uppercase;
		color: $white;
		background-image: linear-gradient(to left, #0fc2dd, #0295bf);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.HomePage__price-block-content {
		padding: 10px 27px 25px;
		min-width: inherit;
		min-height: inherit;
		background-color: $white;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		@media (min-width: 900px) {
			padding-bottom: 83px;
		}
	}

	.HomePage__price-block-price {
		font-family: "BebasNeue";
		font-size: 55px;
		margin-bottom: 15px;
		color: $primary-text-gray;

		span {
			font-family: "Aller";
			font-size: 25px;
			font-weight: $bold;
		}
	}

	.HomePage__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "Aller";
		margin-bottom: 14px;
		height: 43px;
		font-size: 14px;
		font-weight: $bold;
		border-radius: 5px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
		background-color: $primary-orange;
		color: $white;
		text-transform: initial;

		&.bottom-btn {
			width: 149px;
			margin: auto;
		}
	}

	.HomePage__price-block-btn {
		width: 100%;
	}

	.HomePage__search-block {
		width: 366px;
		max-width: calc(100vw - 40px);
		margin: auto;
		margin-right: 0;
		box-shadow: 0 0 50px 0 rgba(10, 99, 116, 0.43);
		@media (max-width: 900px) {
			margin: auto;
		}
		ul {
			list-style: none;

			li {
				margin-bottom: 15px;
				text-align: left;
				color: $gray;
			}
		}

		h3 {
			font-size: 22px;
			padding: 9px 0 4px;
			background-color: $primary-orange;
			color: $white;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}

	.HomePage__search-block-content {
		display: flex;
		justify-content: space-between;
		padding: 27px 30px 40px;
		background-color: $white;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;

		.checkmark {
			display: inline-block;
			margin-right: 8px;

			&:after {
				content: "";
				display: block;
				width: 6px;
				height: 11px;
				border: solid $primary-orange;
				border-width: 0 4px 4px 0;
				transform: rotate(45deg);
			}
		}
	}

	.HomePage__white-background {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		text-align: center;
		min-height: 500px;
		margin: auto;
		@media (min-width: 900px) {
			min-height: 650px;
		}
		&-1 {
			&:before {
				content: "";
				width: 100vw;
				height: 24.932800284vw;
				position: absolute;
				top: -24.932800284vw;
				z-index: -1;
				transform: rotateY(180deg) rotateZ(180deg);
			}
		}
		&-2 {
			z-index: 1;
			background: white;
			&:before,
			&:after {
				content: "";
				width: 100%;
				height: 24.932800284vw;
				position: absolute;
				background: url("/img/white-gradient-triangle.svg");// scss-lint:disable
				z-index: -1;
				background-size: 100% 100%;
			}

			&:before {
				z-index: 1;
				top: -24.932800284vw;
				transform: rotateY(180deg) rotateZ(180deg);
				left: -13px;
				width: calc(100vw + 13px);
			}

			&:after {
				bottom: -24.932800284vw;
				z-index: 1;
				transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg);
			}
		}
	}

	.HomePage__white-background-content {
		max-width: 1023px;
		display: flex;
		flex-direction: column;

		@media (min-width: 900px) {
			max-width: 1023px;
		}
		@media (min-width: 1080px) {
			flex-direction: row;
			justify-content: space-between;
			min-width: 1023px;
		}
		@media (max-width: 1300px) {
			margin: auto 60px;
		}
		@media (max-width: 1079px) {
			margin-top: 80px;
		}
		.column {
			display: flex;
			flex-direction: column;
			width: 467px;
			max-width: calc(100vw - 120px);
			@media (min-width: 1080px) {
				max-width: calc(50vw - 120px);
			}
			.paragraph-wrapper {
				display: flex;
				flex-direction: column;
				margin-bottom: 77px;
				.paragraph-title {
					font-family: BebasNeue;
					font-size: 40px;
					font-weight: normal;
					color: #666666;
					margin-bottom: 16px;
					text-align: left;
				}
				.paragraph-text {
					font-family: Aller;
					font-size: 17px;
					color: #aaaaaa;
					text-align: left;
				}
			}
		}
	}

	.HomePage__business-pro {
		background-color: #2d353c;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		text-align: center;
		min-height: 500px;
		margin: auto;
		padding-top: 180px;

		@media (min-width: 500px) {
			min-height: 800px;
			padding-top: 200px;
		}

		@media (min-width: 700px) {
			min-height: 800px;
			padding-top: 250px;
		}

		@media (min-width: 900px) {
			min-height: 800px;
			padding-top: 300px;
		}

		@media (min-width: 1080px) {
			padding-top: 400px;
		}
		/* &:before {
			content: "";
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			background: inherit;
			z-index: -1;
			top: 0px;
			transform-origin: top right;
			transform: skewY(14deg);
		} */
	}

	.HomePage__business-pro-content {
		max-width: 1023px;
		display: flex;
		flex-direction: column;

		@media (min-width: 1080px) {
			flex-direction: row;
			justify-content: space-between;
			min-width: 1023px;
		}
		@media (max-width: 1079px) {
			margin-top: 80px;
		}
		.column {
			display: flex;
			flex-direction: column;
			width: 467px;
			max-width: calc(100vw - 40px);
			.paragraph-wrapper {
				display: flex;
				flex-direction: column;
				margin-bottom: 77px;
				&-2 {
					@media (max-width: 422px) {
						margin-top: -173px;
					}
					@media (max-width: 462px) {
						margin-top: -100px;
					}
				}
				.paragraph-title {
					width: 467px;
					max-width: calc(100vw - 40px);
					font-family: BebasNeue;
					font-size: 50px;
					line-height: 0.84;
					color: #666666;
					font-weight: 400;
					text-align: left;
					margin-bottom: 50px;
				}
				.paragraph-text {
					width: 417px;
					max-width: calc(100vw - 40px);
					font-family: Aller;
					font-size: 17px;
					color: #aaaaaa;
					text-align: left;
					margin-bottom: 20px;
				}
				.paragraph-img-front {
					width: 468px;
					height: 504px;
					object-fit: contain;
					margin-bottom: 235px;
					@media (max-width: 1079px) {
						margin-bottom: 40px;
						max-width: calc(100vw - 60px);
					}
				}
				.paragraph-img-back {
					width: 481px;
					height: 535px;
					object-fit: contain;
					margin-top: 175px;

					@media (max-width: 1079px) {
						margin-top: 40px;
						max-width: calc(100vw - 60px);
					}
				}
			}
		}
	}

	.HomePage__bottom-background {
		background-color: #2d353c;
		position: relative;
		min-height: 800px;
		border-top: 3px solid #2d353c;
		border-bottom: 3px solid white;
		&:before/* ,
		&:after */ {
			content: "";
			width: calc(100vw + 13px);
			height: 24.932800284vw;
			position: absolute;
			background: url("../img/black-gradient-triangle.svg");
			z-index: -1;
			background-size: 100% 100%;
		}

		&:before {
			z-index: 1;
			top: -24.932800284vw;
			right: -13px;
			transform: rotateX(180deg) rotateY(180deg);
		}

		/* &:after {
			z-index: 1;
			bottom: 0;
			transform: rotateX(180deg) rotateZ(180deg);
		} */

		@media (max-width: 400px) {
			min-height: 1100px;
		}

		@media (min-width: 630px) {
			padding: 80px 30px;
			min-height: 1000px;
		}

		@media (min-width: 910px) {
			padding: 40px 30px 150px 30px;
			min-height: 950px;
		}

		@media (min-width: 961px) {
			padding: 40px 30px 175px 30px;
			min-height: 850px;
		}

		@media (min-width: 1160px) {
			padding: 40px 30px 225px 30px;
			min-height: 850px;
		}

		@media (min-width: 1233px) {
			padding: 40px 30px 280px 30px;
			min-height: 900px;
		}
	}

	.HomePage__bottom-background-content {
		margin: auto 40px;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		@media (max-width: 960px) {
			margin-top: 80px;
		}
		@media (max-width: 900px) {
			align-items: center;
			flex-direction: column;
		}
		@media (max-width: 600px) {
			padding: 40px 20px 100px 20px;
		}

		@media (max-width: 400px) {
			min-height: 1100px;
		}

		@media (min-width: 630px) {
			min-height: 900px;
		}

		@media (min-width: 910px) {
			min-height: 880px;
		}

		@media (min-width: 961px) {
			min-height: 850px;
		}

		@media (min-width: 1160px) {
			min-height: 850px;
		}

		@media (min-width: 1233px) {
			min-height: 900px;
		}

		.black-phone {
			height: auto;
			min-width: 435px;
			max-width: 624px;
			max-height: 1494px;
			transform: translate(0, -100px);
			z-index: 1;
			object-fit: contain;

			@media (max-width: 960px) {
				display: none;
			}
			@media (min-width: 1100px) {
				transform: translate(-20px, -100px);
				height: auto;
			}
			@media (min-width: 1450px) {
				transform: translate(-100px, -100px);
				height: auto;
			}
			@media (min-width: 1650px) {
				transform: translate(-200px, -100px);
				height: auto;
			}
		}
	}

	.HomePage__bottom-background-text {
		position: relative;
		z-index: 1;
		max-width: calc(100vw - 40px);
		margin: auto;
		margin-top: 0;
		@media (min-width: 901px) {
			max-width: 980px;
		}
		@media (min-width: 961px) {
			max-width: 980px;
			transform: translateX(10px);
		}
		@media (min-width: 985px) {
			max-width: 980px;
			transform: translateX(0px);
		}
		@media (min-width: 1020px) {
			max-width: 980px;
			transform: translateX(-20px);
		}
		@media (min-width: 1330px) {
			max-width: 980px;
			transform: translateX(-40px);
		}
		@media (min-width: 1500px) {
			max-width: 980px;
			transform: translateX(-80px);
		}

		.marketing-button {
			text-decoration-line: underline;
			color: $primary-orange;
			font-size: 33px;
			text-transform: uppercase;
			text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.35);
			font-family: BebasNeue;
		}
		.description {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			max-width: calc(100vw - 40px);
			margin-top: 89px;

			@media only screen and (max-width: 851px) {
				flex-direction: column;
			}

			article {
				width: 524px;
				text-align: center;
				display: flex;
				justify-content: space-between;
				margin-bottom: 82px;
				@media only screen and (max-width: 851px) {
					width: calc(100% - 40px);
				}
				.text {
					width: 366px;
					font-family: BebasNeue;
					font-size: 25px;
					color: #ffffff;
					text-align: left;
					font-weight: 400;
					@media only screen and (max-width: 851px) {
						width: calc(100vw - 200px);
					}
				}

				svg {
					width: 70px;
					@media (max-width: 851px) {
						margin-right: 40px;
					}
				}
			}
		}
	}

	.HomePage__tech-img {
		margin-top: 55px;
	}

	.HomePage__pdf-img-wrapper {
		position: relative;
		width: 330px !important;
		margin: auto;
		outline: 0;

		.secondImg-text {
			position: absolute;
			line-height: 23px;
			font-size: 25px;
			padding: 10px;
			height: 57px;
			color: $white;
			background-color: rgba($primary-gray, 0.9);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			border-bottom: 2px solid $primary-orange;

			&:before {
				position: absolute;
				width: 0;
				height: 0;
			}

			&.first {
				top: 115px;
				left: 45px;
				width: 123px;
				text-align: left;

				&:before {
					content: "";
					top: -7px;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-bottom: 7px solid rgba($primary-gray, 0.9);
				}
			}

			&.second {
				top: 160px;
				left: 200px;
				width: 101px;
				text-align: left;

				&:before {
					content: "";
					top: -7px;
					right: 18px;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-bottom: 7px solid rgba($primary-gray, 0.9);
				}
			}

			&.third {
				top: 209px;
				left: 72px;
				width: 122px;
				text-align: left;

				&:before {
					content: "";
					left: -7px;
					top: 3px;
					border-top: 7px solid transparent;
					border-bottom: 7px solid transparent;
					border-right: 7px solid rgba($primary-gray, 0.9);
				}
			}

			&.fourth {
				top: 375px;
				left: 23px;
				width: 180px;
				text-align: right;

				&:before {
					content: "";
					right: -7px;
					top: 5px;
					border-top: 7px solid transparent;
					border-bottom: 7px solid transparent;
					border-left: 7px solid rgba($primary-gray, 0.9);
				}
			}

			@media (min-width: 1024px) {
				display: none;
			}
		}
	}

	.HomePage__pdf-img {
		position: relative;
		width: 100%;
		margin: 25px 0 0;

		@media (min-width: 900px) {
			width: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.circle {
		position: absolute;
		width: 34px;
		height: 34px;
		border: 3px solid $primary-orange;
		border-radius: 50%;

		.line-home-page {
			display: none;
		}

		&.first {
			top: 70px;
			left: 38px;
		}

		&.second {
			top: 96px;
			left: 146px;
		}

		&.third {
			left: 250px;
			top: 217px;
		}

		&.fourth {
			top: 280px;
			left: 160px;
		}

		&.fifth {
			top: 347px;
			left: 135px;
		}

		&.sixth {
			top: 376px;
			left: 205px;
		}

		&.secondImg-first {
			top: 70px;
			left: 45px;
		}

		&.secondImg-second {
			top: 115px;
			left: 258px;
		}

		&.secondImg-third {
			top: 200px;
			left: 30px;
		}

		&.secondImg-fourth {
			top: 370px;
			left: 215px;
		}

		@media (min-width: 1024px) {
			width: 10px;
			height: 10px;
			border: none;
			background-color: $primary-orange;

			.line-home-page {
				display: block;
				position: relative;
				height: 4px;
				width: 40px;
				background-color: $primary-orange;

				.circle-text {
					position: absolute;
					bottom: -15px;
					font-family: "BebasNeue";
					font-size: 25px;
					color: $white;
				}
			}

			.circle-outer {
				position: absolute;
				top: -9px;
				width: 22px;
				height: 22px;
				border: 4px solid $primary-orange;
				border-radius: 50%;
			}

			&.first {
				top: 89px;
				left: 35px;
			}

			&.second {
				top: 116px;
				left: 152px;
			}

			&.third {
				left: 280px;
				top: 253px;
			}

			&.fourth {
				top: 318px;
			}

			&.fifth {
				top: 400px;
				left: 142px;
			}

			&.sixth {
				top: 432px;
				left: 216px;
			}

			&.secondImg-first {
				top: 91px;
				left: 36px;

				.inner-circle.first {
					& + .line-home-page {
						right: 127px;
						width: 61px;

						.circle-text {
							left: -130px;
							width: 102px;
							text-align: right;
							top: -30px;
						}
					}
				}
			}

			&.secondImg-second {
				top: 135px;
				left: 278px;

				.inner-circle.second {
					width: 47px;

					& + .line-home-page {
						top: 37px;
						left: 52px;
						width: 67px;

						.circle-text {
							left: 96px;
							width: 85px;
							text-align: left;
							top: -25px;
						}
					}
				}
			}

			&.secondImg-third {
				top: 239px;
				left: 37px;

				.inner-circle.third {
					width: 61px;
					left: -60px;
					transform-origin: right bottom;
					transform: skewY(-43deg);

					& + .line-home-page {
						top: 50px;
						left: -116px;
						width: 59px;

						.circle-text {
							width: 100px;
							right: 88px;
							top: -25px;
						}

						.circle-outer {
							left: -20px;
						}
					}
				}
			}

			&.secondImg-fourth {
				top: 427px;
				left: 233px;

				.inner-circle.fourth {
					width: 38px;
					left: 1px;
					transform-origin: left bottom;
					transform: skewY(-43deg);

					& + .line-home-page {
						top: -43px;
						left: 41px;
						width: 122px;

						.circle-text {
							left: 151px;
							top: -25px;
							width: 165px;
							text-align: left;
						}

						.circle-outer {
							right: -20px;
							left: initial;
						}
					}
				}
			}
		}
	}

	.inner-circle {
		width: 22px;
		height: 22px;
		background-color: $primary-orange;
		border-radius: 50%;
		position: relative;
		margin: 3px;

		@media (min-width: 1024px) {
			position: relative;
			border-radius: 0;
			height: 5px;

			&.first {
				width: 70px;
				right: 69px;
				transform-origin: right bottom;
				transform: skewY(-43deg);

				& + .line-home-page {
					top: 58px;
					right: 166px;
					width: 100px;

					.circle-outer {
						left: -20px;
					}

					.circle-text {
						left: -112px;
					}
				}
			}

			&.second {
				width: 85px;
				right: -2px;
				transform-origin: left bottom;
				transform: skewY(43deg);

				& + .line-home-page {
					top: 72px;
					left: 89px;
					width: 230px;

					.circle-outer {
						right: -20px;
					}

					.circle-text {
						right: -135px;
					}
				}
			}

			&.third {
				width: 53px;
				right: -2px;
				transform-origin: left bottom;
				transform: skewY(38deg);

				& + .line-home-page {
					top: 34px;
					left: 58px;
					width: 104px;

					.circle-outer {
						right: -20px;
					}

					.circle-text {
						right: -178px;
					}
				}
			}

			&.fourth {
				width: 36px;
				left: -33px;
				transform-origin: right top;
				transform: skewY(38deg);

				& + .line-home-page {
					top: -36px;
					left: -315px;
					width: 285px;

					.circle-outer {
						left: -20px;
					}

					.circle-text {
						left: -150px;
					}
				}
			}

			&.fifth {
				width: 92px;
				left: -91px;
				transform-origin: right bottom;
				transform: skewY(-43deg);

				& + .line-home-page {
					top: 79px;
					left: -303px;
					width: 215px;

					.circle-outer {
						left: -20px;
					}

					.circle-text {
						left: -160px;
						width: 130px;
						bottom: -22px;
						line-height: 25px;
					}
				}
			}

			&.sixth {
				width: 38px;
				right: -2px;
				transform-origin: left bottom;
				transform: skewY(-43deg);

				& + .line-home-page {
					top: -43px;
					left: 43px;
					width: 194px;

					.circle-outer {
						right: -20px;
					}

					.circle-text {
						right: -158px;
						width: 130px;
						bottom: -30px;
					}
				}
			}
		}
	}
}

.pricing-second {
	width: 100vw;
	position: relative;

	// margin-bottom: 140px;
	margin-top: 360px;

	@media (max-width: 850px) {
		margin-top: 270px;
	}
	@media (max-width: 700px) {
		margin-top: 250px;
	}
	@media (max-width: 400px) {
		margin-top: 200px;
	}
	@media (max-width: 364px) {
		margin-top: 150px;
	}

	// @media (min-width: 500px) {
	// 	margin-bottom: 180px;
	// }
	// @media (min-width: 630px) {
	// 	margin-bottom: 200px;
	// }

	// @media (min-width: 700px) {
	// 	margin-bottom: 220px;
	// }

	// @media (min-width: 730px) {
	// 	margin-bottom: 240px;
	// }

	// @media (min-width: 804px) {
	// 	margin-bottom: 270px;
	// }

	// @media (min-width: 900px) {
	// 	min-height: auto;
	// }
	// @media (min-width: 1130px) {
	// 	margin-top: 350px;
	// 	margin-bottom: 320px;
	// }
	// @media (min-width: 1350px) {
	// 	margin-top: 420px;
	// 	margin-bottom: 380px;
	// }
	// @media (min-width: 1580px) {
	// 	margin-top: 400px;
	// 	margin-bottom: 420px;
	// }
	.myaccident-complete {
		font-family: BebasNeue;
		font-size: 50px;
		text-align: center;
		color: #666666;
	}
	.my-accident-pro {
		padding-top: 7px;
		padding-bottom: 50px;
		width: fit-content;
		margin: auto;
		font-family: Aller;
		font-size: 17px;
		color: #aaaaaa;
	}
	// .oval {
	// 	margin: 0 auto;
	// 	margin-bottom: 20px;
	// 	width: 35px;
	// 	height: 35px;
	// 	padding: 0;
	// 	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	// 	background-color: #f29338;
	// 	border-radius: 50%;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	&-number {
	// 		font-family: BebasNeue;
	// 		font-size: 30px;
	// 		color: #ffffff;
	// 	}
	// }

	.my-accident-reports {
		margin: 0 auto;
		margin-bottom: -16px;
		width: 170px;
		font-family: Aller;
		font-size: 17px;
		font-weight: bold;
		text-align: center;
		color: #aaaaaa;
	}

	.my-accident-plus {
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
		font-family: BebasNeue !important;
		font-size: 40px !important;
		color: #f29338;
		margin-top: 10px;
	}

	.report-img {
		margin: 0 auto;
		margin-bottom: -16px;
	}
	.pdf-report-img {
		margin: 0 auto;
		width: 317px;
		height: 449px;
		object-fit: contain;
	}
	.pricing-content {
		position: relative;
		z-index: 2;
		max-width: 980px;
		margin: 0 auto;

		&-second {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			max-width: 790px;
			margin: auto;
			@media only screen and (min-width: 1005px) {
				flex-direction: row;
				img {
					max-width: 100%;
				}
			}
			&-content {
				display: flex;
				flex-direction: column;
				.report-img {
					height: 248px;
					@media (max-width: 1004px) {
						height: 372px;
					}
					margin-top: 25px;
					&-1 {
						width: 200px;
						@media (max-width: 1004px) {
							width: 300px;
						}
					}
					&-2 {
						width: 179px;
						@media (max-width: 1004px) {
							width: 268.5px;
						}
					}
					&-3 {
						width: 175.6px;
						@media (max-width: 1004px) {
							width: 263.4px;
						}
					}
				}
			}
		}
		&-deliver {
			margin: 80px auto;
			width: 524px;
			min-height: fit-content;
			background-color: #f29338;
			max-width: calc(100vw - 100px);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;

			@media (max-width: 576px) {
				align-items: center;
				height: 76px;
			}
			@media (max-width: 373px) {
				height: 113px;
			}
			@media (min-width: 577px) {
				align-items: center;
				height: 48px;
			}
			&-text {
				object-fit: contain;
				font-family: BebasNeue;
				font-size: 25px;
				text-align: center;
				color: #ffffff;
			}
		}
	}
}
.one-two-three {
	.column {
		position: relative;
		text-align: center;
		svg {
			position: absolute;
			margin-left: -20px;
			filter: brightness(3.5);
		}
		h2 {
			position: relative;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			text-shadow: rgb(0, 0, 0) 2px 2px 7px;
			@media (max-width: 700px) {
				height: 106px;
			}
		}
	}
	overflow: hidden;
	margin-bottom: 80px;
	@media (max-width: 700px) {
		.column {
			margin-bottom: 100px;
		}
	}
}
