.MarketingPage {
	width: 100%;
	@media (min-width: 1200px) {
		margin-top: -82px;
	}

	h1,
	h2,
	h3,
	h5,
	h6 {
		font-family: "BebasNeue";
		font-weight: normal;
		margin-bottom: 0;
	}

	p,
	li,
	a,
	button,
	label .button {
		font-family: "Aller";
	}

	.button,
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 34px;
		width: 207px;
		height: 43px;
		background-color: #f29338;
		color: white;

		span {
			font-size: 14px;
			font-weight: bold;
			text-transform: initial;
		}
	}

	.white-bkg__content {
		// background-image: ("/img/circle-blue.png");
	}

	.AdvertisementMarkets,
	.AdvertisementPage__faq,
	.InsurancePage__top,
	.black-bkg__content,
	.white-bkg__content,
	.bottom-bkg__content,
	.top-bkg__content,
	.bottom-bkg-content {
		position: relative;
		z-index: 2;
		width: 72%;
		margin: 0 auto;

		@media (min-width: 1000px) {
			max-width: 987px;
		}
		.success {
			font-size: 14px;
			font-weight: bold;
			color: #f29338;
			margin-bottom: 10px !important;
		}

		.error {
			font-size: 14px;
			font-weight: bold;
			color: red;
			margin-bottom: 10px !important;
		}

		.nothing {
			font-size: 14px;
			font-weight: bold;
			color: transparent;
			margin-bottom: 10px !important;
		}

		.markets-button {
			background-color: transparent;
			box-shadow: none;
			margin-top: 17px;
			padding: 0;
			height: fit-content;
			span {
				font-family: Helvetica;
				text-decoration-line: underline;
				-moz-text-decoration-line: underline;
				font-size: 13px;
			}
		}
	}

	.black-bkg__content {
		@media only screen and (max-width: 957px) {
			width: 80%;
		}

		@media only screen and (max-width: 851px) {
			width: 90%;
		}
		@media only screen and (max-width: 851px) {
			width: 72%;
		}
	}

	.AdvertisementMarkets {
		width: 100%;
		height: 635px;
		padding: 30px 30px 76px;
		margin-bottom: 20px;
		opacity: 0.9;
		border-radius: 5px;
		background-color: #161a1d;
	}

	.AdvertisementMarkets__content {
		margin-bottom: 40px;
		text-align: center;

		h1 {
			font-size: 50px;
			color: #f29338;
		}

		p {
			font-size: 17px;
			color: #ffffff;
			text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.35);
		}
	}

	.list-map-wrapper {
		display: flex;
		justify-content: space-between;
		max-height: 371px;
		.markets-map {
			width: 50%;
		}

		ul {
			width: calc(50% - 70px);
			list-style: none;
			max-height: 371px;
			overflow-y: auto;
			margin-right: 70px;
			padding-right: 7px;
			padding-bottom: 76px;
			direction: rtl;

			&::-webkit-scrollbar-track {
				padding: 5px 0;
				background-color: transparent;
			}

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 4px;
				height: 80px;
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				background-color: #3f444a;
			}

			@media only screen and (max-width: 600px) {
				width: 100%;
			}
		}

		li {
			font-size: 15px;
			padding: 18px 28px;
			margin-bottom: 1px;
			font-weight: bold;
			color: #828d97;
			direction: ltr;
			text-align: left;
			&:nth-child(odd) {
				background-color: #3f454a;
			}

			&:nth-child(even) {
				background-color: #363b40;
			}
		}
	}

	.AdvertisementPage__top-bkg,
	.InsurancePage__bottom-bkg {
		position: relative;
		width: 100%;
		height: calc(47.5% + 82px);
		&.reduce-height {
			height: calc(60% + 82px);

			&:after {
				background-image: url("/img/dallas-dark.jpg");
			}
		}

		&.bottom:after {
			background-image: url("/img/dallas2.jpg");
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: -2px;
			left: 0;
			right: 0;
			background-image: url("/img/architecture-buildings-city.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: bottom;
		}
	}

	.AdvertisementPage__top-bkg {
		padding: 226px 0 100px;
		&:after {
			@media only screen and (max-width: 876px) {
				bottom: -20px;
			}
			@media only screen and (max-width: 408px) {
				bottom: -40px;
			}

			@media only screen and (max-width: 338px) {
				bottom: -60px;
			}

			@media only screen and (max-width: 303px) {
				bottom: -110px;
			}
		}
	}

	.AdvertisementPage__bottom-bkg {
		min-height: 43%;
		position: relative;
		background-color: #262d33;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			transform-origin: top right;
			transform: skewY(9deg);
			background-color: inherit;
		}
	}

	.AdvertisementPage__faq {
		article {
			padding-bottom: 20px;

			&:last-child {
				padding-bottom: 200px;
			}
		}

		a {
			color: #f29338;
		}

		h5,
		h6 {
			font-weight: normal;
			color: #ffffff;
			margin-bottom: 20px;
		}

		h5 {
			font-size: 35px;
		}

		h6 {
			font-size: 25px;
		}

		p {
			font-size: 16px;
			color: #aaaaaa;
			font-weight: bold;
		}
	}

	.InsurancePage__top {
		display: flex;
		flex-wrap: wrap;
		& > h1 {
			width: 100%;
			margin-top: -50px;
			margin-bottom: 86px;
			text-align: center;
		}
		@media only screen and (max-width: 876px) {
			flex-direction: column;
		}

		article {
			width: 50%;
			height: 50%;

			@media only screen and (max-width: 876px) {
				width: 100%;
				height: 100%;
			}
		}

		img {
			width: 50%;
			height: 50%;
			min-width: 305px;
			min-height: 453px;
			@media only screen and (max-width: 876px) {
				position: relative;
				left: 50%;
				transform: translateX(-6%);
			}
			@media only screen and (max-width: 700px) {
				position: relative;
				left: 50%;
				transform: translateX(-20%);
			}
			@media only screen and (max-width: 540px) {
				position: relative;
				left: 50%;
				transform: translateX(-35%);
				top: 30px;
			}
			@media only screen and (max-width: 450px) {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		article {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		h1 {
			font-size: 55px;
			line-height: 1;
			color: #ffffff;
			font-weight: normal;

			span {
				color: #f29338;
			}
		}
	}

	.InsurancePge__black-bkg {
		position: relative;
		padding: 50px 0 65px;
		background-color: #262d33;

		@media only screen and (max-width: 876px) {
			padding-top: 20px;
		}

		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 120%;
			position: absolute;
			background: inherit;
		}

		&:before {
			top: -23px;
			transform-origin: top left;
			transform: skewY(-13deg);
			@media only screen and (max-width: 1492px) {
				top: -75px;
			}

			@media only screen and (max-width: 965px) {
				top: -75px;
			}

			@media only screen and (max-width: 876px) {
				top: -110px;
			}

			@media only screen and (max-width: 793px) {
				top: -150px;
			}

			@media only screen and (max-width: 583px) {
				top: -180px;
			}
		}

		&:after {
			bottom: 0;
			transform-origin: left bottom;
			transform: skewY(10deg);
		}
	}

	.description,
	.program {
		display: flex;
		justify-content: space-between;
	}

	.description {
		.text {
			transform: translateY(-80px);
			font-size: 25px;
			text-transform: uppercase;
			color: #ffffff;
		}
		@media only screen and (max-width: 851px) {
			flex-direction: column;
		}

		article {
			width: calc(33% - 11px);
			text-align: center;
			@media only screen and (max-width: 851px) {
				width: 90%;
			}
		}
	}

	.title {
		font-size: 17px;
		margin-bottom: 20px;
		color: #ffffff;
		text-align: center;
	}

	.program {
		@media only screen and (max-width: 771px) {
			flex-wrap: wrap;
		}

		a {
			width: calc(25% - 11px);
			height: 148px;
			@media only screen and (max-width: 771px) {
				width: calc(50% - 22px);
				margin-bottom: 24px;
			}

			@media only screen and (max-width: 442px) {
				width: calc(100%);
				margin-bottom: 24px;
			}
			.legal {
				// background-image: url("/img/legal.jpg");
				width: 100%;
			}
		}

		.medical {
			// background-image: url("/img/medical.jpg");
		}

		.insurance {
			// background-image: url("/img/insurance.jpg");
		}

		.auto {
			// background-image: url("/img/auto.jpg");
		}

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: calc(25% - 11px);
			height: 148px;
			background-size: cover;
			background-repeat: no-repeat;
			position: relative;
			border-radius: 5px;
			box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5),
				0 2px 4px 0 rgba(0, 0, 0, 0.5);
			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: hsla(208, 15%, 17%, 0.7);
			}

			@media only screen and (max-width: 771px) {
				width: calc(50% - 22px);
				margin-bottom: 24px;
			}

			@media only screen and (max-width: 442px) {
				width: calc(100%);
				margin-bottom: 24px;
			}
		}

		h3 {
			font-size: 35px;
			color: #f29338;
			z-index: 1;
		}

		p {
			font-size: 12px;
			color: #ffffff;
			text-transform: uppercase;
			font-weight: bold;
			z-index: 1;
		}
	}

	.InsurancePage__white-bkg {
		position: relative;
		padding-top: 330px;
		background-color: #ffffff;

		@media only screen and (max-width: 1400px) {
			padding-top: 270px;
		}

		@media only screen and (max-width: 1165px) {
			padding-top: 240px;
		}

		@media only screen and (max-width: 1025px) {
			padding-top: 200px;
		}

		@media only screen and (max-width: 950px) {
			padding-top: 180px;
		}

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			background: inherit;
			z-index: 1;
			bottom: 0;
			transform-origin: left bottom;
			transform: skewY(10deg);
		}
	}

	.white-bkg__content {
		display: flex;
		justify-content: space-between;

		@media only screen and (max-width: 836px) {
			flex-direction: column;
		}

		h2 {
			font-size: 50px;
			margin-bottom: 10px;
			color: #666666;
		}

		p {
			font-size: 17px;
			margin-bottom: 15px;
			color: #aaaaaa;
		}

		article {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;
			@media only screen and (max-width: 836px) {
				width: 100%;
			}
		}

		img {
			@media only screen and (max-width: 950px) {
				width: 350px;
				height: 677px;
			}
			@media only screen and (max-width: 900px) {
				width: 320px;
				height: 619px;
			}
			@media only screen and (max-width: 836px) {
				width: 70%;
				height: 70%;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
			}
			@media only screen and (max-width: 430px) {
				width: 90%;
				height: 90%;
			}
		}
	}

	.InsurancePage__bottom-bkg {
		padding: 330px 0 230px;
	}

	.bottom-bkg__content {
		width: 53.6%;
		background-color: rgba(0, 0, 0, 0.8);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;

		@media only screen and (max-width: 940px) {
			width: 60%;
		}

		@media only screen and (max-width: 807px) {
			width: 70%;
		}

		@media only screen and (max-width: 688px) {
			width: 80%;
		}

		@media only screen and (max-width: 500px) {
			width: 90%;
		}

		@media only screen and (max-width: 400px) {
			padding: 50px 30px;
		}

		h2 {
			font-size: 50px;
			text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.18);
			color: #f29338;
			margin-bottom: 30px;
			text-align: center;
		}

		p {
			font-size: 17px;
			color: #ffffff;
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.PersonalInjury__top-bkg {
		// background-image: url("/img/personal-injury.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom;
		height: 916px;

		h1 {
			font-size: 50px;
			line-height: 1.1;
			margin-bottom: 30px;
			color: #ffffff;

			@media only screen and (max-width: 400px) {
				padding-top: 120px;
			}

			@media only screen and (max-width: 374px) {
				padding-top: 200px;
			}
			span {
				color: #f29338;
			}
		}

		p {
			font-size: 17px;
			color: #ffffff;
			margin-bottom: 10px;
		}

		button {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
		}
	}

	.top-bkg__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 100vh;

		input {
			width: 26%;
			height: 50px;
			border-radius: 0;
			outline: 0;
			border: 0;
			box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
		}
	}

	.PersonalInjury__white-bkg {
		.white-bkg__content {
			display: block;
			padding: 85px 0 85px;
		}

		article {
			width: 100%;
			margin-bottom: 30px;
		}

		img {
			width: 100%;
			margin-bottom: 70px;
		}

		h2 {
			font-size: 50px;
			line-height: 1.1;
			margin-bottom: 50px;
			color: #666666;
		}

		h3 {
			font-size: 30px;
			margin-bottom: 15px;
			color: #666666;
		}

		a {
			color: #f29338;
			text-decoration-line: underline;
			text-decoration-color: #f29338;
		}

		.input-wrapper {
			position: relative;
			padding: 17px 20px 47px;
			width: 100%;
			margin-bottom: 50px;
			background-image: linear-gradient(to left, #0fc2dd 105%, #0295bf 0%);

			p {
				font-size: 16px;
				margin-bottom: 12px;
				font-weight: bold;
				color: #ffffff;
			}

			input {
				height: 50px;
				margin-bottom: 0;
				border: 0;
				border-radius: 0;
				outline: 0;

				&::placeholder {
					font-size: 15px;
					color: #78a6b2;
				}
			}

			.search-icon-wrapper {
				position: absolute;
				right: 20px;
				top: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 50px;
				width: 55px;
				transform: translateY(-50%);
				background-color: #f29338;
				cursor: pointer;
			}
		}
	}

	.white-bkg__content-inner {
		display: flex;
		justify-content: space-between;
		@media only screen and (max-width: 936px) {
			flex-direction: column;
		}
	}

	.white-bkg__content__inner1,
	.white-bkg__content__inner2 {
		width: calc(50% - 40px);

		@media only screen and (max-width: 936px) {
			width: calc(100% - 80px);
		}

		@media only screen and (max-width: 525px) {
			width: 100%;
		}
	}

	.PersonalInjury-bottom-bkg {
		position: relative;
		padding: 145px 0 120px;
		background-color: $primary-gray;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			min-height: inherit;
			background-color: $secondary-gray;
			clip-path: polygon(100% 0%, 0% 0, 50% 100%);
		}

		.form-input-group {
			position: relative;
		}

		h2 {
			font-size: 50px;
			color: #ffffff;
			margin-bottom: 45px;
		}

		article {
			display: flex;
			justify-content: space-between;
			margin-bottom: 25px;

			.form-input-group {
				width: calc(50% - 15px);
			}
		}

		button {
			width: 123px;
			height: 45px;
		}

		label {
			font-family: "Aller";
			position: absolute;
			left: 10px;
			top: -5px;
			padding: 0 7px;
			font-size: 13px;
			font-weight: bold;
			text-transform: uppercase;
			color: #ffffff;
			background-color: rgb(45, 53, 60);
		}

		input,
		textarea {
			height: 50px;
			outline: 0;
			border-radius: 0;
			border: solid 3px #666666;
			background-color: rgba(45, 53, 60, 0.5);
			color: #ffffff;
		}

		textarea {
			height: 90px;
			padding: 15px;
		}
	}
}

.city-bg {
	// background-image: url("/img/dallas-dark.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	padding: 100px 0;
}
.content-box {
	width: 95%;
	max-width: 1000px;
	margin: auto;
	background: rgba(24, 37, 44, 0.5);
	color: #fff;
	padding: 20px;
	position: relative;
	&.content-box2 {
		background: rgba(37, 50, 57, 0.8);
	}
	&.city {
		// background-image: url(/img/dallas3-50opacity.jpg);
		background-size: cover;
		h1 {
			font-size: 44px;
			font-weight: 100;
			margin-bottom: 20px;
			font-family: BebasNeue;
			span {
				color: #f29338;
			}
		}
	}
	button,
	.button {
		background: #0295bf;
		width: auto;
	}
	h1 {
		text-align: center;
	}
	.greenish-box {
		background: #3b545bb3;
		border-radius: 10px;
		padding: 15px !important;
		margin: 10px 10px 10px 0;
		font-size: 18px;
		position: relative;
		text-align: left;
		strong {
			display: block;
			color: #f29338;
			margin-bottom: 5px;
			font-size: 22px;
			span {
				color: #fff;
			}
		}
		.top-pick {
			position: absolute;
			background: #f29338;
			right: -15px;
			top: 10px;
			transform: rotate(35deg);
			font-size: 14px;
			padding: 2px 10px;
			border-radius: 5px;
		}
		.most-popular {
			position: absolute;
			background: #f29338;
			left: 0;
			top: -20px;
			font-size: 14px;
			padding: 2px 10px;
			border-radius: 5px 5px 0 0;
		}
		&.right {
			float: right;
			@media (max-width: 700px) {
				display: none;
			}
			button {
				width: 100%;
				margin-top: 10px;
			}
		}
		&.greenish2 {
			background: rgb(24, 100, 128);
			text-align: center;
		}
		&.mobile {
			display: none;
			@media (max-width: 700px) {
				display: block;
				text-align: center;
			}
		}
	}
	ul {
		margin: 20px 20px 0;
		li {
			margin: 10px 0;
			text-align: left;
		}
	}
	ul.orange {
		color: #f29338;
		text-align: left;
		li {
			margin: 20px;
		}
	}
	&.legal {
		padding: 50px;
		text-align: center;
		color: #cccccc;
		p {
			margin-bottom: 15px;
			line-height: 1.7;
		}
		.title {
			font-size: 22px;
		}
	}
}
.dark-bg {
	background: #2e363b;
	h1 {
		text-align: center;
	}
	.title {
		text-align: center;
		color: #fff;
		span {
			color: #f29338;
		}
	}
}
.clients-not-clicks {
	position: absolute;
	width: 110px;
	top: 5px;
	left: -55px;
	z-index: 2;
	@media (max-width: 700px) {
		display: none;
	}
}
.green-gradient {
	background: linear-gradient(45deg, transparent, #21566c);
	width: 100%;
	height: 50px;
	left: 0;
	position: absolute;
	border-radius: 5px;
	@media (max-width: 700px) {
		position: relative;
		height: auto;
		margin: 0 -10px;
		width: calc(100% + 20px);
		background: none;
		border-bottom: #828d97 2px solid;
		border-radius: 0;
	}
}
.types-of-leads {
	.greenish-box {
		margin-left: 20px;
	}
}
.section-h1 {
	color: rgb(255, 255, 255);
	font-size: 60px !important;
	width: 95%;
	max-width: 1000px;
	margin: 0 auto 40px !important;
	padding-top: 30px;
	border-bottom: #828d97 2px solid;
}
