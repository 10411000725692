.AboutPage {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: $primary-gray;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);

  .AbouPage__content {
    padding: 40px 20px 55px;


    @media(min-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 140px 0 100px;
      max-width: 980px;
      margin: auto;
    }
  }

  .AboutPage__circle-shape {
    position: absolute;
    circle {
      stroke-width: 120;
      stroke-opacity: .4;
    }

    @media(min-width: 1024px) {
      circle {
        stroke-width: 90;
      }
    }
  }

  .AboutPage__circle-shape1 {
    top: 0;
    left: 0;
    right: 0;
    height: 678px;
    width: 100%;

    @media(min-width: 1024px) {
      left: -25%;
    }
  }

  .AboutPage__circle-shape2 {
    display: none;

    @media(min-width: 1024px) {
      display: block;
      position: absolute;
      right: -195px;
      bottom: 312px;
      width: 100%;
    }
  }
  .AboutPage__article {
    margin-bottom: 45px;
    &:first-of-type {
      p {
        margin-bottom: 20px;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    @media(min-width: 1024px) {
      width: 50%;
      margin-bottom: 80px;

      &:first-of-type {
        align-self: flex-start;
        text-align: left;
      }

      &:nth-of-type(2) {
        align-self: flex-end;
        text-align: right;
        width: 80%;
      }

      &:last-of-type {
        align-self: flex-start;
        text-align: left;
        width: 80%;
        margin-bottom: 60px;
      }
    }
  }

  .AboutPage__text {
    &--bold-white {
      color: $white;
      font-weight: $bold;
    }

    &--white {
      color: $white;
    }

    &--no-margin-bottom {
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }

  h1,
  h2 {
    font-family: 'BebasNeue';
    padding: 0 20px;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 15px;
    font-weight: $light;
    color: $gray;

    @media(min-width: 1020px) {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  p,
  ol li {
    font-family: 'Aller';
    font-size: 17px;
    color: $secondary-text-gray;
  }

  span.text-bold {
    font-weight: $bold;
  }

  ol {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 35px;
    line-height: 22px;
  }

  a {
    color: $primary-orange;
  }

  ol {
    list-style: none;

    li {
      margin-bottom: 30px;
    }
  }
}