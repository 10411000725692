::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.attorneyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: calc(100vw - 250px);
  position: absolute;
  left: 250px;
  @media only screen and (max-width: 1220px) {
    width: fit-content;
    flex-direction: column;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
    position: relative;
    left: 146px;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
    position: relative;
    left: unset;
  }
}
.attorneyAd {
  position: relative;
  max-height: calc(100vh - 150px);
  max-width: 100vw;
  overflow-y: scroll;
  margin-top: 5px;
  z-index: 5;
  width: 335px;
  border-radius: 5px;
  background-color: white;
  box-shadow: none;
  border: 1px solid #eeeeee;
  margin-left: 7%;
  @media only screen and (max-width: 1220px) {
    margin-left: unset;
  }
  &-preview {
    max-height: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    background-image: url("/img/circle-blue.svg");
    overflow: hidden;
    margin-left: unset;
    margin-right: 7%;
    @media only screen and (max-width: 1220px) {
      margin-right: unset;
    }
  }

  &-switch-container {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #dddddd;
    height: 150px;
    padding: 10px 15px 10px 15px;

    .about-top {
      font-size: 16px;
      text-align: center;
      margin-bottom: 6px;
    }

    .about-bottom {
      font-size: 10px;
      width: 100px;
      text-align: center;
    }

    .switch {
      margin-top: 10px;
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  &.confirm-step {
    height: 320px;
    overflow-y: unset;

    .attorneyAd__content {
      height: 320px;
      justify-content: space-between;
      padding: 30px 20px 30px;
    }
  }

  &.no-scroll {
    overflow-y: unset;
  }

  &.advertisement {
    z-index: 6;
    width: 440px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.hide {
    display: none;
  }

  h3,
  p,
  span,
  input {
    font-family: "Helvetica";
  }

  h3 {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #333333 !important;
    margin: 0 !important;
  }

  .close-ad {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 16px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  .ad-title-ads {
    color: #555555;
    font-size: 22px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 15px;
    text-align: center;
  }

  .attorneyAd__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px;

    &-title {
      font-size: 22px !important;
      font-weight: bold !important;
      color: #333333 !important;
      margin-bottom: 2px !important;
      text-align: center;
    }

    &-form {
      width: 100%;
      margin-top: 20px;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      height: 100px;
      justify-content: space-between;
      &-two {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }

    &-button {
      width: fit-content;
      align-self: center;
      background-color: transparent;
      margin-bottom: 5px !important;
      border: none;
      cursor: pointer;
      padding: 0 2px;

      &-title {
        color: #555555;
        font-size: 12px;
        text-align: center;
      }
    }

    .upload-button {
      width: fit-content;
      align-self: center;
      background-color: #0eafd9;
      padding: 6px 10px;
      cursor: pointer;
      border: none;
      border-radius: 2px;
      margin-bottom: 3px;
      &-submit {
        background-color: #f29338;
      }
      &-text {
        font-weight: 600;
        font-size: 14px;
      }
    }

    &.inside-ads {
      width: 400px;
      margin: auto;
    }
  }

  .abbot-img {
    width: 69px;
    height: 69px;
    margin-top: 21px;
    margin-bottom: 12px;
    border-radius: 50%;
    border: 4px solid #f29338;
  }

  .abbot-address {
    font-size: 14px;
    color: #999999;
    text-align: center;
    max-width: 280px;
  }

  .star-wrapper {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    align-items: baseline;
    margin-top: 3px;
    margin-bottom: 12.5px;
  }

  .rating {
    margin-right: 4px;
    margin-top: 1px;
    color: #f29338;
  }

  .star {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.9em;
    margin-right: 0.9em;
    margin-bottom: 1.2em;
    margin-top: 3px;
    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid #f29338;
    border-left: 0.3em solid transparent;

    font-size: 10px;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0.6em;
      left: -1em;
      border-right: 1em solid transparent;
      border-bottom: 0.7em solid #f29338;
      border-left: 1em solid transparent;
      transform: rotate(-35deg);
    }

    &:after {
      transform: rotate(35deg);
    }
  }

  .star-img {
    width: 16.6px;
    height: 15.8px;
  }

  .text-wrapper {
    height: 95px;
    width: 100%;
    padding-top: 20px;
    border-radius: 2px;
    background-color: #eeeeee;
    text-align: center;
    padding: 9px 8px 8px 7px;
    &-ads {
      margin-bottom: 10px;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.no-bkg {
      background: transparent;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      color: #555555;
      margin-bottom: 5px !important;
    }

    .text {
      font-size: 12px;
      font-weight: bold;
      color: #888888;
      margin-bottom: 5px !important;
    }

    .number {
      font-size: 17px;
      font-weight: bold;
      color: #f29338;
      text-decoration: underline;
      text-decoration-color: #f29338;
    }
  }

  form {
    margin: 14px 0 14px;
  }

  .form-input-group {
    position: relative;
    margin-bottom: 16px;
    width: 100%;

    label {
      position: absolute;
      top: -6px;
      left: 10px;
      padding: 0 6px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      background: white;
      color: #555555;
    }

    .form-input {
      width: 100%;
      height: 50px;
      padding-left: 10px;
      margin-bottom: 5px;
      box-sizing: border-box;
      border: solid 3px #dddddd;
      outline: 0;

      &-uneditable {
        padding-top: 13px;
      }

      &.message-input {
        height: 90px;
        padding: 10px;
      }

      .lookup-input {
        border: none;
        margin: 5px 0 0 -8px;
        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }

      .lookup-break {
        border-bottom: 3px solid #dddddd;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        height: 0;
        width: 125px;
        position: absolute;
        left: 82px;
        top: 55px;
      }

      .lookup-options {
        z-index: 2;
        background-color: #ffffff;
        position: absolute;
        left: -5px;
        top: 60px;
        border: 3px solid #d3dbe0;
        border-radius: 3px;
        max-height: 200px;
        overflow-y: scroll;
        width: calc(100% + 10px);
        -webkit-box-shadow: 0px 5px 14px -4px rgba(227, 243, 255, 0.91);
        -moz-box-shadow: 0px 5px 14px -4px rgba(227, 243, 255, 0.91);
        box-shadow: 0px 5px 14px -4px rgba(227, 243, 255, 0.91);
        .lookup-option {
          padding: 4px 3px 4px 7px;
          font-family: Helvetica;
          cursor: pointer;
          &:nth-child(odd) {
            background-color: #ebf7fc;
            border-bottom: 1px solid #f1f1f1;
          }
          &:nth-child(even) {
            background-color: #e3f3ff;
          }
        }
      }

      .lookup-loading {
        z-index: 2;
        background-color: #ffffff;
        position: absolute;
        left: 1px;
        top: 50px;
        padding: 3px 3px 3px 5px;
        border: 2px solid #cccccc;
        height: 40px;
        width: 100%;
      }
    }
  }

  .checkbox-wrapper {
    &-2 {
      position: relative;
    }
    span {
      font-size: 14px;
      padding-left: 30px;
      font-weight: bold;
      color: #555555;
    }
  }

  .blue-text {
    color: #0eafd9;
    text-decoration: underline;
    text-decoration-color: #0eafd9;
  }

  [type="checkbox"].filled-in:checked + span:not(.lever):after {
    background-color: #0eafd9;
    border-color: #0eafd9;
  }

  input:not([type]):focus:not([readonly]),
  input[type="text"]:not(.browser-default):focus:not([readonly]),
  input[type="password"]:not(.browser-default):focus:not([readonly]),
  input[type="email"]:not(.browser-default):focus:not([readonly]),
  input[type="url"]:not(.browser-default):focus:not([readonly]),
  input[type="time"]:not(.browser-default):focus:not([readonly]),
  input[type="date"]:not(.browser-default):focus:not([readonly]),
  input[type="datetime"]:not(.browser-default):focus:not([readonly]),
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
  input[type="tel"]:not(.browser-default):focus:not([readonly]),
  input[type="number"]:not(.browser-default):focus:not([readonly]),
  input[type="search"]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]) {
    box-shadow: none !important;
    border-bottom: 3px solid #dddddd;
  }

  .checkbox-text {
    font-size: 11px;
    line-height: 16px;
    font-weight: bold;
    color: #999999;
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 0;
    outline: 0;
    border-radius: 3px;
    background-color: #0eafd9;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: -3px;
      color: white;
      text-transform: uppercase;
    }
  }

  .success-msg {
    font-size: 14px;
    font-weight: bold;
    color: #f29338;
    margin-bottom: 10px !important;
  }

  .error {
    font-size: 14px;
    font-weight: bold;
    color: red;
    margin-bottom: 10px !important;
  }

  .nothing {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px !important;
  }

  .Add__confirm-step {
    min-height: calc(100% - 70px);
    text-align: center;
  }

  .success-title {
    font-size: 18px;
    margin: 30px 0 30px;
    font-weight: bold;
    color: #f29338;
  }

  .success-text {
    font-size: 15px;
    margin: 30px 0 30px;
    font-weight: bold;
    color: #555555;
  }
}

.success-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 335px;
  height: 324px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-img {
    width: 65px;
    height: 65px;
    margin-top: 54px;
    margin-bottom: 26px;
  }

  &-title {
    font-size: 18px;
    font-weight: bold;
    color: #f29338;
  }

  &-text {
    font-size: 15px;
    margin: 30px 0 30px;
    font-weight: bold;
    color: #555555;
  }

  &-button {
    border: none;
    width: 295px;
    height: 50px;
    border-radius: 3px;
    background-color: rgba(14, 175, 217, 1);
    align-items: center;
    justify-content: center;
    &:focus {
      background-color: rgba(14, 175, 217, 0.6);
    }

    &-text {
      margin: 0 auto;
      width: fit-content;
      text-align: center;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
}

.upload-image {
  margin: 10px 0;
}
