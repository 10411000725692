.affiliate-landing {
  .content-box {
    margin-top: 24px;
  }
  .questionnaire-text {
    margin: 48px 0;
  }
}

@media (max-width: 700px) {
  .affiliate-landing {
      .HomePage__title {
        margin: 48px auto;
      }

    .HomePage__title-questionnaire {
      margin: 48px auto 0
    }

    .questionnaire-text {
      text-align: center;
      margin: 12px auto 48px;
      width: 90%;
      line-height: 1.4;
    }

    .affiliate-form {
      margin-top: 48px;
      h2 {
        font-size: 22px;
        margin-bottom: 24px;
      }
      input {
        margin-bottom: 16px;
        height: 40px;

      }
      button {
        width: 90%;
        font-weight: 700;
        height: 48px;
        font-size: 16px;
        margin: 16px 0 24px;
      }
    }

      .content-box {
        padding: 32px 20px;
        border-radius: 8px;
        margin-top: 0;
        p {
          line-height: 1.4;
        }
      }
    }

}

