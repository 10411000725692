//primary-colors
$primary-orange: #f29338;
$primary-gray: #2d353c;
$primary-blue: #0fc2dd;
$white: #ffffff;
$primary-text-gray: #54616c;

//secondary-colors
$secondary-blue: #0295bf;
$secondary-gray: #293037;
$secondary-text-gray: #aaaaaa;
$footer-text-color: #585858;

//other-colors
$gray: #666666;

//font-weight
$light: 100;
$medium: 500;
$bold: 700;

//Elment height 
$header-height-mobile: 72px;
$header-height-desktop: 81px;
$footer-height-mobile: 70px;
$footer-height-desktop: 50px;

