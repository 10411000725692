.safari .Header {
	background-color: $primary-gray;
	border-bottom: 0;
	z-index: 10;
}
.signUp-btn-text {
	margin-right: 0;
}
.Header {
	width: 100vw;
	display: flex;
	justify-content: center;
	padding: 20px;
	background-color: $primary-gray;
	border-bottom: 0;
	z-index: 10;
	.dash {
		display: none;
		@media (min-width: 800px) {
			display: inline;
			margin-right: 10px;
		}
	}

	@media (min-width: 800px) {
		justify-content: space-between;
		align-items: center;

		&.transparent {
			background-color: transparent;
		}
	}

	nav {
		width: 100%;

		a,
		span {
			font-family: "Aller";
			font-size: 15px;
			margin-right: 0;
		}

		a {
			font-weight: $bold;
			user-select: none;
		}
	}
	.main-logo {
		width: 60px;

		@media (min-width: 900px) {
			width: 77px;
		}
	}

	.mobile-menu-toggle {
		position: absolute;
		right: 20px;
		top: 25px;
		margin-right: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		border: none;
		outline: 0;
		background: transparent;
		span {
			display: block;
			width: 20px;
			height: 2px;
			margin-right: 0;
			background-color: $white;
			margin-bottom: 5px;
			border-radius: 5px;
		}

		@media (min-width: 768px) {
			span {
				width: 25px;
				margin-bottom: 6px;
			}
		}

		@media (min-width: 800px) {
			display: none;
		}
	}

	.Header__menu {
		display: none;

		&.gray-bkg {
			background-color: $primary-gray;
			color: $white;

			@media (min-width: 800px) {
				background-color: transparent;
			}
		}

		&--white-links {
			a {
				color: $white;
			}
		}

		.signUp-btn {
			display: inline-block;
			padding: 9px 16px;
			background-color: $primary-orange;
			border-radius: 5px;
			box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);
		}

		&--open {
			position: absolute;
			left: 0;
			top: $header-height-mobile;
			padding: 20px;
			height: fit-content;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;
			width: 100%;
			z-index: 100;
			border-bottom: 3px solid $primary-orange;

			a {
				margin-bottom: 25px;
				margin-right: 0;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			span {
				margin-bottom: 25px;
			}
		}

		@media (min-width: 800px) {
			display: block;
			margin-right: 0;

			a {
				margin-right: 30px;
			}

			.signIn {
				margin-left: 16px;
			}

			.signIn-text {
				padding-left: 15px;
				&:before {
					content: "|";
					position: relative;
					right: 20px;
				}
			}

			.signUp-btn {
				margin-right: 0;
			}
		}
	}
}
